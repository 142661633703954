export default{
  namespaced:true,

  state:() => ({
    compra:{
      id:null,
      monto:0,
      concepto:'',
      fecha:null,
      proveedor_id:null,
      proveedor:null
    },

    compras:[],
   
  }),


  getters:{
    draft:(state) => clone(state.compra)
  },

  mutations:{
    clear(state){
      state.compra = {
        id:null,
        monto:0,
        concepto:'',
        fecha:null,
        proveedor_id:null,
        proveedor:null
      }
    },

    setCompra(state,compra){
      if(compra){
        state.compra = compra
      }
    },

    setCompras(state,compras){

      if(compras.length){
        state.compras = compras
      }

    }
  },

  actions:{

    fetch({commit},compra_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/compras/${compra_id}/fetch-data`).then(({data}) => {
          commit('setCompra',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/compras/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/compras/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/compras`,datos).then(({data}) => {

            if(data.result){
              commit('setCompra',data.compra)
            }

            resolve(data)

          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},compra_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/compras/${compra_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

 

  }
}