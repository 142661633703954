export default {
  namespaced:true,
  state:() =>({
    
    pickup:{
      id:null,
      nombre:'',
      lng:null,
      lat:null,
      hore:null,
      observacion:''
    },

    pickups:[]

  }),

  getters:{
    draft:(state) => clone(state.pickup)
  },



  mutations:{

    clear(state){
      state.pickup = {
        id:null,
        nombre:'',
        lng:null,
        lat:null,
        hore:null,
        observacion:''
      }
    },


    setPickups(state,pickups){
      if(pickups){
        state.pickupes = pickups
      }
    },


    setPickup(state,pickup){
      if( pickup){
        state.pickup = pickup
      }
    }


  },


  actions:{

    fetch({commit},pickup_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/pick-ups/${pickup_id}/fetch-data`).then(({data}) => {

          commit('setPickup',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/pick-ups/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/pick-ups/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/pick-ups`,datos).then(({data}) => {
            
            if(data.result){
              commit('setPickup',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},pickup_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/pick-ups/${pickup_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getPickupsForTour({commit},tour_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/pick-ups/tour/${tour_id}/list-select`).then(({data}) => resolve(data)).catch(e =>reject(e))
      })
    }



  }

}