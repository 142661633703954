export default{

	namespaced:true,

	state(){
		return{

			panel:{
				id      : null,
				nombre  : '',
        descripcion: '',
				permisos:[],
			},
			
			panels:[],
		}
	},

  getters:{

		draft(state){
			return clone(state.panel);
		},
	},

  mutations:{

		setPanel(state,panel){
			state.panel = panel

		},

		setPanels(state,panels){

			state.panels = panels
		
		},


		clear(state){

			state.panel = {
					id      : null,
				nombre  : '',
        descripcion: '',
				permisos:[],
			}
		
		},


	},



	actions:{
		
		getPanels({state,commit}){
			
			return new Promise((resolve, reject) => {
					axios.get('/api/panels/get-all').then(({data}) => {
					commit('setPanels',data);
					resolve(data)
				}).catch(e => {
					reject(e)
				})
			})
			


		},

		

	}

}