export default{
  namespaced:true,

  state:() => ({
    ticket:{
      id:null,
      reserva_id:null,
      agencia_id: null,
      cantidad: null,
      adultos : 0,
      menores : 0,
      infantes : 0,
      precio_adulto:0,
      precio_menor:0,
      precio_infante:0,
      precio_total:0,
      comprobante:null,
      estatus:1
    },

    tickets:[],
  }),


  getters:{
    draft:(state) => clone(state.ticket)
  },

  mutations:{

    clear(state){
      state.ticket = {
        id:null,
        reserva_id:null,
        agencia_id: null,
        cantidad: null,
        adultos : 0,
        menores : 0,
        infantes : 0,
        precio_adulto:0,
        precio_menor:0,
        precio_infante:0,
        precio_total:0,
        comprobante:null,
        estatus:1
      }
    },

    setTicket(state,ticket){
      if(ticket){
        state.ticket = ticket
      }
    },


    setTickets:(state,tickets) => {
      state.tickets = tickets
    },

  },

  actions:{

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {

        axios.post(`/api/tickets-vendidos/agencias/fetch-data`,datos).then(({data}) => {

          commit('setTickets',data.tickets);
          resolve(data);

        }).catch(e => {
          reject(e)
        });

      })
    },


    enviarPago({commit},datos){
      return new Promise((resolve, reject) => {

        let formData = new FormData();

        Object.keys(datos).forEach(k => {
          formData.append(k,datos[k])
        });


        axios.post(`/api/tickets-vendidos/pagar-tickets`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
        }).then(({data}) => {
            resolve(data)
        }).catch(e => reject(e))

      })
    },


    togglePago({commit},{ticket_id,estatus}) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/tickets-vendidos/${ticket_id}/toggle-pago`,{estatus}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
    

  }
}