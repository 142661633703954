export default {
  namespaced:true,
  state:() =>({
    
    precio:{
      id:null,
      precio:0,
      tipo_persona:1,
      tour_id:''
    },

    precios:[]

  }),

  getters:{
    draft:(state) => clone(state.precio)
  },



  mutations:{

    clear(state){
      state.precio = {
         id:null,
        precio:0,
        tipo_persona:1,
        tour_id:''
      }
    },


    setPrecios(state,precios){
      if(precios){
        state.precios = precios
      }
    },


    setPrecio(state,precio){
      if( precio){
        state.precio = precio
      }
    }


  },


  actions:{

    fetch({commit},precio_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/precios/${precio_id}/fetch-data`).then(({data}) => {

          commit('setPrecio',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/precios/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/precios/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/precios`,datos).then(({data}) => {
            
            if(data.result){
              commit('setPrecio',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},precio_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/precios/${precio_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }



  }

}