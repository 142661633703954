export default{
  namespaced:true,

  state:() => ({
    fijo:{
      id:null,
      monto:'',
      rol_id:null,
      fecha_pago:1,
      usuario_id:null,
      is_user:false,
      por_tour:true,
      rol:null,
      usuario:null
    },

    fijos:[]
  }),


  getters:{
    draft:(state) => clone(state.fijo),
    optionsFechaPago:(state) => {
      const legends = [
        {text:'Todos los domingos de cada mes',value:1},
        {text:'Todos los 15  y últimos de cada mes',value:2}, 
        { text: 'Todos los últimos de cada mes', value: 3 }
      ];
      return legends
    }

  },

  mutations:{
    clear(state){
      state.fijo = {
        id:null,
        monto:'',
        rol_id:null,
        fecha_pago:1, // 1 => Todos los domingos , 2 => todos los 15 y los ultimos , 3 => todos los ultimos de mes
        usuario_id:null,
        is_user:false,
        por_tour:true,
        rol:null,
        usuario:null
      }
    },

    
    setFijo(state,fijo){
      if(fijo){
        state.fijo = fijo
      }
    }
  },

  actions:{

    fetch({commit},fijo_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/fijos/${fijo_id}/fetch-data`).then(({data}) => {
          commit('setFijo',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/fijos/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/fijos/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/fijos`,datos).then(({data}) => {
            if(data.result){
              commit('setFijo',data.fijo)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},fijo_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/fijos/${fijo_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    }

  }

}