import store from '@/store';

export default [

  {
      path:'/',
      name:'home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
      },
  },

   /*****************************************/
   /* Tours
   /*************************************** */
   {
    path:'/perfiles/tours',
    component: () => import('views/perfiles/index.vue'),
    children:[
      {
        path:'',
        name:'perfil.tour.list',
        component: () => import('views/perfiles/promotor/tourList.vue'),
        meta:{
              resource:'Perfil tour',
              action:'read',
              pageTitle:'Tours'
            },
      },

      {
        path:'tips',
        name:'perfil.tour.tips',
        component: () => import('views/perfiles/promotor/tourTips.vue'),
        meta:{
          resource:'Perfil tour',
          action:'read',
          pageTitle:''
        },
      },


      {
        path:':id/itinerario',
        name:'perfil.tour.itinerario',
        component: () => import('views/perfiles/promotor/tourItinerario.vue'),
        props:true,
        beforeEnter: (to, from, next) => {
          store.dispatch('tour/fetch',to.params.id).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        meta:{
              resource:'Perfil tour',
              action:'write',
              pageTitle:'Itinerario',
              navActiveLink:'perfil.tour.list',
              breadcrumb:[
                  {text:'Tours',to:{name:'perfil.tour.list'},active:false},
                  {text:'Itinerario',active:true},
               ]
        },
      },

      {
        path:':id/acerca',
        name:'perfil.tour.acerca',
        component: () => import('views/perfiles/promotor/tourAcerca.vue'),
        props:true,
        beforeEnter: (to, from, next) => {
          store.dispatch('tour/fetch',to.params.id).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        meta:{
              resource:'Perfil tour',
              action:'write',
              pageTitle:'Acerca del Tour',
              navActiveLink:'perfil.tour.list',
              breadcrumb:[
                  {text:'Tours',to:{name:'perfil.tour.list'},active:false},
                  {text:'Acerca',active:true},
               ]
        },
      },

     

     
    ]
   },

   /*****************************************/
   /* Convenio y terminos de pasajero
   /*************************************** */


   {
    path:'/pasajero/terminos',
    name:'perfil.pasajero.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Pasajero').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Término no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },




   /*****************************************/
   /* Actividades 
   /*************************************** */

   {
      path:'/actividads',
      component:() => import('views/actividades/list.vue'),
      name:'perfil.actividad.list',
      meta:{
          resource:'actividades',
          action:'read',
          pageTitle:'Eventos en el Destino (TravelPoints)',
        }
     
    }

];
