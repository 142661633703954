
export default{
	namespaced:true,

	state(){
		return{
			chat:{
				salida_id:null,
				salida:null,
				archivado:false,
				tipo_chat:1, // 1 => p2p y 2 => grupal
				mensajes:[],
				integrantes:[]
			},

			chats:[

			]
		}
	},


	getters:{


	},

	mutations:{
	

		setChats(state,data){
			state.chats = data;
		},

		pushChat(state,chat){
			state.chats.push(chat);
		},


		setChat(state,chat){
			state.chat = chat
		},

		clear(state){
			state.chat = {
				salida_id:null,
				salida:null,
				archivado:false,
				tipo_chat:1, // 1 => p2p y 2 => grupal
				mensajes:[],
				integrantes:[]
			}
		},

		agregarMensaje(state,{chat_id,mensaje}){

			if(state.chat.id === chat_id ){
				state.chat.mensajes.push(mensaje)
			}

		},

		eliminarMensaje(state,msg_id){
			if(state.chat.id && state.chat.mensajes.length){
				let msg = state.chat.mensajes.findIndex((v) =>  v.id === msg_id)
				if(msg != -1){
					state.chat.mensajes.splice(msg,1)
				}
			}
		
		}

		

	},

	actions:{

		fetch({commit},chat_id){
			return new Promise((resolve, reject) => {
				
				axios.get(`/api/chats/${chat_id}/fetch-data`).then(({data}) => {
					commit('setChat',data)
					resolve(data)
				}).catch(e => reject(e))

			})
		},


		fetchChatsAndContacts({commit},usuario_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/usuarios/${usuario_id}/chats/chats-and-contacts`)
          .then(({data}) => {
						commit('setChats',data.chats)
						resolve(data)
					})
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/apps/chat/users/profile-user')
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
    getChat(ctx, { userId }) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get(`/apps/chat/chats/${userId}`)
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post(`/apps/chat/chats/${contactId}`, { message, senderId })
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },

		abrirChat({commit},{usuario_id}){
			return new Promise((resolve, reject) => {
				
				axios.get(`/api/chat/aperturar-chat-user/${usuario_id}`).then(({data}) => {
					commit('setChat',data.chat)
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		archivar({commit},chat_id){
			return new Promise((resolve, reject) => {
				if(chat_id){
					axios.get(`/api/chats/${chat_id}/archivar-chat`).then(({data}) => resolve(data)).catch(e => reject(e))
				}else{
					resolve({result:false})
				}
			})
		},

		fetchDataArchivados({commit},datos){
			return new Promise((resolve, reject) => {
				axios.post(`/api/chats/archivados/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject(e))

			})
		},

		eliminar({commit},chat_id){
			return new Promise((resolve, reject) => {
				
				axios.delete(`/api/chats/${chat_id}`).then(({data}) => resolve(data)).catch(e  => reject(e))

			})
		},

		eliminarMensaje({commit},msg_id){
			commit('eliminarMensaje',msg_id)
			return new Promise((resolve,reject) => {
				axios.delete(`/api/chats/eliminar-mensaje/${msg_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
			});

		}
	}

}
