export default{
  namespaced:true,

  state:() => ({
    comision:{
      id:null,
      comision:0,
      rol_id:null,
      rol:null,
      tipo_acreditacion:1,
    },

    comisiones:[]
  }),


  getters:{
    draft:(state) => clone(state.comision)
  },

  mutations:{
    clear(state){
      state.comision = {
        id:null,
        comision:0,
        rol_id:null,
        rol:null,
        tipo_acreditacion:1,

      }
    },

    setComision(state,comision){
      if(comision){
        state.comision = comision
      }
    }
  },

  actions:{

    fetch({commit},comision_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/perfil-comisions/${comision_id}/fetch-data`).then(({data}) => {
          commit('setComision',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/perfil-comisions/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){
      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/perfil-comisions/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/perfil-comisions`,datos).then(({data}) => {
            if(data.result){
              commit('setComision',data.comision)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},comision_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/perfil-comisions/${comision_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    }

  }

}