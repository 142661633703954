import store from '@/store';

import {computed} from 'vue';

const agencia_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.agencia){
    return usuario.agencia.id
  }
  return null
});

export default [
  {
    path:'/',
    name:'home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
         
      },
  },
  
    /*****************************************/
   /* Promotores Agencia
   /*************************************** */

  {
    path:'/agencias/promotores/',
    component:() => import('components/Views.vue'),
    children:[
      {
        path:'',
        name:'listar.promotores.agencia',
        props:{tipoUser:'Promotores',isAgencia:true},
        component: () => import('views/usuarios/lists.vue'),
        meta:{
            resource: 'Promotores',
            action: 'read',
            pageTitle:'Promotores',
            breadcrumb:[
              {
                  text:'listado',
                  active:true
              }
            ]
        }
      },
      {
            path:'create',
            name:'create.promotor.agencia',
            props:{tipoUser:'Promotores',isAgencia:true},
            component: () => import('views/usuarios/create.vue'),
            meta:{
               pageTitle: 'Crear Promotor',
               navActiveLink: 'listar.promotores.agencia',
               resource: 'Promotores',
               action: 'write',
               breadcrumb: [
                  {
                     text: 'Promotores',
                     active: false,
                     to:{name:'listar.promotores.agencia'}
                  },

                  {
                     text: 'crear',
                     active: true
                  }
               ]
            }
            

         },
         {
            path: ':id/edit',
            props:route => ({
               id:route.params.id,
               tipoUser:'Promotores',
               isAgencia:true
            }),
            name: 'edit.promotor.agencia',
            component: () => import('views/usuarios/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('usuario/fetch',to.params.id).then((data) => {
                  if(data){
                     next()
                  }else{
                     next({name:'error-404'})
                  }
               }).catch(e => next({name:'error-404'}))
               
            },

            meta: {
               pageTitle:'Editar Promotor',
               navActiveLink: 'listar.promotores.agencia',
               resource: 'Promotores',
               action: 'update',
               breadcrumb: [

                   {
                     text: 'Promotores',
                     active: false,
                     to:{name:'listar.promotores.agencia'}
                  },

                  {
                     text: 'editar',
                     active: true
                  }
               ]
            }
         }
    ]
   
   
  },

   /*****************************************/
   /* Convenio y terminos de Agencia
   /*************************************** */


   {
    path:'/agencia/terminos',
    name:'perfil.agencia.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Agencia').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Termino no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },


   {
    path:'/agencia/convenios',
    name:'perfil.agencia.convenios',
    beforeEnter: (to, from, next) => {
      store.dispatch('termino/fetchTerminos',{model_id:agencia_id.value,model_type:'Agencia'}).then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Convenio no asociado')
        }
      })
    },
    component: () => import('views/perfiles/termino.vue'),
    meta:{
      resource:'Perfil convenios',
      action:'read',
    },
   },

  

    /*****************************************/
   /* Tickets Vendidos
   /*************************************** */

   {
    path:'/tickets-vendidos',
    component:() => import('components/Views.vue'),
    children:[
      {
        path:'',
        component: () => import('views/tickets/vendidos.vue'),
        name:'tickets.vendidos',
        meta:{
          resource:'Tickets vendidos',
          action:'read',
          pageTitle:'Tickets Vendidos',
        }
      }
    ]
   },



    /*****************************************/
   /* Reportes
   /*************************************** */
  {
    path:'/reportes',
    component: () => import('components/Views.vue'),
    children:[
      {
        path:'agencia/ocupacion',
        name:'reporte.agencia.ocupacion',
        component:() => import('views/reportes/agencias/ocupacion.vue'),
        meta:{
          pageTitle:'Pasajeros',
          resource:'Reporte de ocupación',
          action:'read',
        }
      },

      {
        path:':agenciaId?/agencia/ganancias',
        name:'reporte.agencia.ganancias',
        props:(route) => ({
          agencia_id: route.params.agenciaId ?? agencia_id.value 
        }),
        component: () => import('views/reportes/agencias/ganancias.vue'),
        meta:{
          resource:'Reporte agencia ganancias',
          action:'read',
          pageTitle:'Ganancias por Promotor'
        }
      },

      {
        path:'agencia/economico',
        name:'reporte.agencia.economico',
        component:() => import('views/reportes/agencias/reporteEconomico.vue'),
        meta:{
          pageTitle:'Reporte económico de la operación',
          resource:'Agencia reporte económico de la operación',
          action:'read',
        }
      },

      {
        path:'agencia/cupones-asignados',
        name:'reporte.agencia.cupones',
        component:() => import('views/reportes/agencias/cuponesAsignados.vue'),
        meta:{
          resource:'Reporte de cupones asignados',
          action:'read',
          pageTitle:'Cupones Asignados'
        }
      },
      {
        path:'agencia/ingresos',
        name:'reporte.agencia.ingresos',
        component:() => import('views/reportes/agencias/ingresos.vue'),
        meta:{
          resource:'Reporte de ingresos',
          action:'read',
          pageTitle:'Reporte de Ingresos'
        }
      }

      

      
    ]
  }
];
