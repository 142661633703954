import store from '@/store';
import Error404 from '@/views-core/error/Error404.vue'
import Login from '@/views-core/pages/authentication/Login.vue';
import ForgotPassword from 'views/auth/ForgotPassword.vue'
import PasswordReset from 'views/auth/PasswordReset.vue';
import EstablecerContrasena from 'views/auth/EstablecerContrasena.vue'
import NotAuthorized from '@/views-core/pages/miscellaneous/NotAuthorized.vue'
import { before } from 'lodash';

export default [
   {
      path:'/',
      name:'home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
      },
   },

   {
    path: '/error-404',
    name: 'error-404',
    component:Error404,
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'login',
    component:Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/reset-password',

    props: (route) => ({
      token: (route.query.token),
      email: (route.query.email),
    }),
    name: 'auth-reset-password',
    component: PasswordReset,
    meta: {
      layout: 'full',
      resource:'Auth'
    },
  },

  {
    path: '/usuario/:usuario/establecer/contrasena',
    props:true,
    name: 'establecer-contrasena',
    component:EstablecerContrasena,
    beforeEnter: (to, from, next) => {
         store.dispatch('usuario/verificarStatus',to.params.usuario).then(({is_password}) => {
            if(!is_password){
               next()
            }else{
               next({name:'login'})
            }
         }).catch(e => next({name:'login'}))

    },

    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },

 
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component:NotAuthorized,
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  
  
   /*****************************************/
   /* MI Perfil
   /*************************************** */

   {
      path:'/perfil',
      name: 'mi-perfil',
      component: () => import('views/perfil/index.vue'),
      meta: {
         pageTitle: 'Mi perfil',
         resource: 'perfil',
         action: 'read',
         breadcrumb: [
         {
            text: 'Configuración de perfil',
            active: true,
         },
         ],
      },
   },

  

   /*****************************************/
   /* USUARIO
   /*************************************** */

   {
      path:'/usuarios',
      // name:'usuarios',
      component: () => import('views/usuarios/index.vue'),
      children:[
         {
            path:'',
            name:'listar.usuarios',
            props:{tipoUser:'Administradores'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Usuarios',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },
         {
            path:'/promotores',
            name:'listar.promotores',
            props:{tipoUser:'Promotores'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Promotores',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },

          {
            path:'/encargados-agencias',
            name:'listar.encargados.agencias',
            props:{tipoUser:'Agencia'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Encargados de agencia',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },
         


         {
            path:'/pasajeros',
            name:'listar.pasajeros',
            props:{tipoUser:'Pasajeros'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Pasajeros',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },
          {
            path:'/choferes',
            name:'listar.choferes',
            props:{tipoUser:'Choferes'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Choferes',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },

         {
            path:'transportistas',
            name:'listar.transportistas',
            props:{tipoUser:'Transportista'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Transportistas',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },

          {
            path:'/traveliers',
            name:'listar.traveliers',
            props:{tipoUser:'Traveliers'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Traveliers',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },
         {
            path:'/encargados-negocios',
            name:'listar.users.negocios',
            props:{tipoUser:'encargados-negocios'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Encargados de Negocios',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },

         {
            path:'/coordinadores',
            name:'listar.coordinadores',
            props:{tipoUser:'Coordinadores'},
            component: () => import('views/usuarios/lists.vue'),
            meta:{
               resource: 'usuarios',
               action: 'read',
               pageTitle:'Encargados de Negocios',
               breadcrumb:[
                  {
                     text:'listado',
                     active:true
                  }
               ]
            }
         },


         {
            path:'create/:tipoUser?',
            name:'create.usuario',
            props:true,
            component: () => import('views/usuarios/create.vue'),
            meta:{
               pageTitle: (text) => `Agregar ${text}`,
               navActiveLink: 'listar.usuarios',
               resource: 'usuarios',
               action: 'write',
               breadcrumb: [
                  {
                     text: 'Usuarios',
                     active: false,
                  },

                  {
                     text: 'crear',
                     active: true
                  }
               ]
            }
            

         },
         {
            path: ':id/edit/:tipoUser?',
            props:route => ({
               id:route.params.id,
               tipoUser:route.params.tipoUser
            }),
            name: 'edit.usuario',
            component: () => import('views/usuarios/edit.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('usuario/fetch',to.params.id).then((data) => {
                  if(data){
                     next()
                  }else{
                     next({name:'error-404'})
                  }
               }).catch(e => next({name:'error-404'}))
               
            },

            meta: {
               pageTitle: (text) => `Editar ${text}`,
               navActiveLink: 'listar.usuarios',
               resource: 'usuarios',
               action: 'update',
               breadcrumb: [

                  {
                     text: 'Usuarios',
                     active: false,
                  },

                  {
                     text: 'editar',
                     active: true
                  }
               ]
            }
         }
      ]
   },



   /*****************************************/
   /* ROL DE USUARIO
   /*************************************** */

   {
      path: '/roles',
      component: () => import(  'views/roles/index.vue'),
      exact:false,
      children: [
         {
            path: '',
            name: 'listar.roles',
            component: () => import('views/roles/lists.vue'),
            meta: {
               pageTitle: 'Rol de usuarios',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.role',
            component: () => import('views/roles/create.vue'),
            meta: {
               pageTitle: 'Crear Rol',
               navActiveLink:'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props:true,
            name: 'edit.role',
            component: () => import('views/roles/edit.vue'),
            beforeEnter: (to, from, next) => {
                  store.dispatch('rol/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
            },

            meta: {
               pageTitle: 'Editar Rol',
               navActiveLink: 'listar.roles',
               breadcrumb: [
                  {
                     text: 'Rol de usuarios',
                     active: false,
                     to: { name: 'listar.roles' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'roles',
               action: 'update'
            }
         },

      ]
   },


   /*****************************************/
   /* PERMISOS DE USUARIO
   /*************************************** */

   {
      path: '/permisos',
      component: () => import('views/permisos/index.vue'),
      exact: false,
      children: [
         {
            path: '',
            name: 'listar.permisos',
            component: () => import('views/permisos/lists.vue'),
            meta: {
               pageTitle: 'Permisos de usuario',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'read'
            }
         },
         {
            path: 'create',
            name: 'create.permiso',
            component: () => import('views/permisos/create.vue'),
            meta: {
               pageTitle: 'Crear Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Crear',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'write'
            }
         },
         {
            path: ':id/edit',
            props: true,
            name: 'edit.permiso',
            component: () => import('views/permisos/edit.vue'),
            beforeEnter: (to, from, next) => {
                  store.dispatch('permiso/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
            },
            meta: {
               pageTitle: 'Editar Permiso',
               navActiveLink: 'listar.permisos',
               breadcrumb: [
                  {
                     text: 'Permisos de usuario',
                     active: false,
                     to: { name: 'listar.permisos' }
                  },

                  {
                     text: 'Editar',
                     active: true,
                  },
               ],
               resource: 'permisos',
               action: 'update'
            }
         },

         

      ]
   },

   /*****************************************/
   /* Negocios
   /*************************************** */

   {
      path:'/negocios',
      component:() => import('views/negocios/index.vue'),
      children:[
         {
            path:'',
            name:'listar.negocios',
            component:() => import('views/negocios/lists.vue'),
            meta:{
               pageTitle:'Negocios',
               resource:'negocios',
               action:'read',
            }
         },

          {
            path:'create',
            name:'create.negocio',
            component:() => import('views/negocios/create.vue'),
            meta:{
               pageTitle:'Crear negocio',
               resource:'negocios',
               action:'write',
               navActiveLink:'listar.negocios',
               breadcrumb:[
                  {text:'Negocios',to:{name:'listar.negocios'},active:false},
                  {text:'Crear',active:true},

               ]

            }
         },

         {
            path:':id/edit',
            name:'edit.negocio',
            component:() => import('views/negocios/edit.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.negocio.negocio.id === to.params.id){
                  next()
               }else{
                  store.dispatch('negocio/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Editando',
               resource:'negocios',
               action:'write',
               navActiveLink:'listar.negocios',
               breadcrumb:[
                  {text:'Negocios',to:{name:'listar.negocios'},active:false},
                  {text:'Editar',active:true},

               ]

            }
         },

         {
            path:':id/comisiones',
            name:'comision.negocio',
            component:() => import('views/negocios/comisiones.vue'),
            props:true,
            meta:{
               // pageTitle:'Comisiones',
               resource:'comisiones',
               action:'read',
               navActiveLink:'listar.negocios',
               breadcrumb:[
                  {text:'Negocios',to:{name:'listar.negocios'},active:false},
                  {text:'Comisiones',active:true},
               ]

            }
         },

          {
            path:':id/show',
            name:'show.negocio',
            component:() => import('views/negocios/show.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.negocio.negocio.id === to.params.id){
                  next()
               }else{
                  store.dispatch('negocio/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Datos del negocio',
               resource:'comisiones',
               action:'read',
               navActiveLink:'listar.negocios',
               breadcrumb:[
                  {text:'Negocios',to:{name:'listar.negocios'},active:false},
                  {text:'Datos',active:true},
               ]

            }
         },

         {
            path:':id/datos-facturacion',
            name:'negocio.datos.facturacion',
            component:() => import('views/negocios/facturacion.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.negocio.negocio.id === to.params.id){
                  next()
               }else{
                  store.dispatch('negocio/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Datos de Facturación',
               resource:'negocios',
               action:'read',
               navActiveLink:'listar.negocios',
               breadcrumb:[
                  {text:'Negocios',to:{name:'listar.negocios'},active:false},
                  {text:'Datos Facturación',active:true},
               ]

            }
         },





         
      ]
   },

   /*****************************************/
   /* proveedores
   /*************************************** */

    {
      path:'/proveedors',
      component:() => import('views/proveedores/index.vue'),
      children:[
         {
            path:'',
            name:'listar.proveedores',
            component:() => import('views/proveedores/list.vue'),
            meta:{
               pageTitle:'Proveedores',
               resource:'proveedores',
               action:'read',
            }
         },

          {
            path:'create',
            name:'create.proveedor',
            component:() => import('views/proveedores/create.vue'),
            meta:{
               pageTitle:'Crear proveedor',
               resource:'proveedores',
               action:'write',
               navActiveLink:'listar.proveedores',
               breadcrumb:[
                  {text:'Proveedores',to:{name:'listar.proveedores'},active:false},
                  {text:'Crear',active:true},

               ]

            }
         },

         {
            path:':id/edit',
            name:'edit.proveedor',
            component:() => import('views/proveedores/edit.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.proveedor.proveedor.id === to.params.id){
                  next()
               }else{
                  store.dispatch('proveedor/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Editando',
               resource:'proveedores',
               action:'write',
               navActiveLink:'listar.proveedores',
               breadcrumb:[
                  {text:'Proveedores',to:{name:'listar.proveedores'},active:false},
                  {text:'Editar',active:true},

               ]

            }
         },

         

          {
            path:':id/show',
            name:'show.proveedor',
            component:() => import('views/proveedores/show.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.proveedor.proveedor.id === to.params.id){
                  next()
               }else{
                  store.dispatch('proveedor/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Datos del proveedor',
               resource:'proveedores',
               action:'read',
               navActiveLink:'listar.proveedores',
               breadcrumb:[
                  {text:'Proveedores',to:{name:'listar.proveedores'},active:false},
                  {text:'Datos',active:true},
               ]

            }
         },




         
      ]
   },


   /*****************************************/
   /* Comisiones
   /*************************************** */

   {
      path:'/comisiones',
      component:() => import('views/comisiones/index.vue'),
      children:[
         {
            path:'',
            name:'listar.comisiones',
            component:() => import('views/comisiones/list.vue'),
            meta:{
               pageTitle:'Comisiones',
               resource:'comisiones',
               action:'read',
            }
         },
         {
            path:'create',
            name:'create.comision',
            component:() => import('views/comisiones/create.vue'),
            meta:{
               pageTitle:'Comisiones',
               resource:'comisiones',
               navActiveLink:'listar.comisiones',
               action:'write',
               breadcrumb:[
                  {text:'Comisiones',to:{name:'listar.comisiones'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },
         {
            path:':id/edit',
            name:'edit.comision',
            component:() => import('views/comisiones/edit.vue'),
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.comision.comision.id === to.params.id){
                  next()
               }else{
                  store.dispatch('comision/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },

            meta:{
               pageTitle:'Comisiones',
               resource:'comisiones',
               navActiveLink:'listar.comisiones',
               action:'update',
               breadcrumb:[
                  {text:'Comisiones',to:{name:'listar.comisiones'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Invitaciones
   /*************************************** */

   {
      path:'/invitaciones',
      component:() => import('views/invitaciones/index.vue'),

      children:[
         {
            path:'',
            name:'invitaciones',
            component:() => import('views/invitaciones/list.vue'),

            meta:{
               pageTitle:'Invitaciones a unirse a CityFuntastic',
               resource:'invitaciones',
               action:'read',
            }
         },

           {
            path:'create',
            name:'create.invitacion',
            component:() => import('views/invitaciones/create.vue'),

            meta:{
               pageTitle:'Creando invitación',
               resource:'invitaciones',
               action:'read',
               navActiveLink:'invitaciones',
               breadcrumb:[
                  {text:'Invitaciones',to:{name:'invitaciones'},active:false},
                  {text:'crear',active:true},
               ]
            }
         }

      ]
   },

   {
      path:'/new-user/invitacion/:invitacion',
      name:'form.invitacion',
      props:true,
      beforeEnter: (to, from, next) => {
         store.dispatch('invitacion/fetchPublic',to.params.invitacion).then(({id, registrado}) => {
        
            if(id && registrado == "No"){
               next()
            }else{
               toast.info('Usuario ya registrado')
               next({name:'login'})
            }
         }).catch(e => {
            console.log('errors',e)
            next({name:'login'})
         })
      },
      component:() => import('views/auth/registro.vue'),
      meta:{
         layout:'full',
         resource:'Auth',
         action:'read'
      }

      
   },

   /*****************************************/
   /* Tours
   /*************************************** */

   {
      path:'/tours',
      component:() => import('views/tours/index.vue'),
      children:[
         {
            path:'',
            name:'tour.list',
            component:() => import('views/tours/list.vue'),
            meta:{
               resource:'tour',
               action:'read',
               pageTitle:'Tours',
            }
         },

          {
            path:'create',
            name:'tour.create',
            component:() => import('views/tours/create.vue'),
            meta:{
               resource:'tour',
               action:'write',
               pageTitle:'Crear Tour',
               navActiveLink:'tour.list',
               breadcrumb:[
                  {text:'Tours',to:{name:'tour.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'tour.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               store.dispatch('tour/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            component:() => import('views/tours/edit.vue'),
            meta:{
               resource:'tour',
               action:'write',
               pageTitle:'Editar Tour',
               navActiveLink:'tour.list',
               breadcrumb:[
                  {text:'Tours',to:{name:'tour.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         },

         {
            path:':id/imagenes',
            name:'tour.imagenes',
            props:true,
            
            beforeEnter: (to, from, next) => {
               store.dispatch('tour/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            component:() => import('views/tours/imagenes.vue'),
            meta:{
               resource:'tour',
               action:'update',
               pageTitle:'Imagenes del tour',
               navActiveLink:'tour.list',
               breadcrumb:[
                  {text:'Tours',to:{name:'tour.list'},active:false},
                  {text:'Imagenes',active:true},
               ]
            }
         }



      ]
   },

   /*****************************************/
   /* Lugares turísticos
   /*************************************** */

   {
      path:'/lugares-turisticos',
      component:() => import('views/lugares-turisticos/index.vue'),
      children:[
         {
            path:'',
            name:'lugar.list',
            component:() => import('views/lugares-turisticos/list.vue'),
            meta:{
               resource:'lugares turísticos',
               action:'read',
               pageTitle:'Lugares Turísticos',
            }
         },

          {
            path:'create',
            name:'lugar.create',
            component:() => import('views/lugares-turisticos/create.vue'),
            meta:{
               resource:'lugares turísticos',
               action:'write',
               pageTitle:'Crear Lugar turístico',
               navActiveLink:'lugar.list',
               breadcrumb:[
                  {text:'Lugares turísticos',to:{name:'lugar.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'lugar.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.tour.tour.id === to.params.id){
                  next()
               }else{
                  store.dispatch('lugarTuristico/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/lugares-turisticos/edit.vue'),
            meta:{
               resource:'lugares turísticos',
               action:'write',
               pageTitle:'Editar Lugar',
               navActiveLink:'lugar.list',
               breadcrumb:[
                  {text:'Lugares turísticos',to:{name:'lugar.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Pick Ups 
   /*************************************** */

   {
      path:'/pick-ups',
      component:() => import('views/pickups/index.vue'),
      children:[
         {
            path:'',
            name:'pickup.list',
            component:() => import('views/pickups/list.vue'),
            meta:{
               resource:'pickups',
               action:'read',
               pageTitle:'Pick Ups',
            }
         },

          {
            path:'create',
            name:'pickup.create',
            component:() => import('views/pickups/create.vue'),
            meta:{
               resource:'pickups',
               action:'write',
               pageTitle:'Registrar nuevo pick up',
               navActiveLink:'pickup.list',
               breadcrumb:[
                  {text:'Pick ups',to:{name:'pickup.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'pickup.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.tour.tour.id === to.params.id){
                  next()
               }else{
                  store.dispatch('pickup/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/pickups/edit.vue'),
            meta:{
               resource:'pickups',
               action:'write',
               pageTitle:'Editar Pick up',
               navActiveLink:'pickup.list',
               breadcrumb:[
                  {text:'Pick ups',to:{name:'pickup.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Recomendaciones 
   /*************************************** */

   {
      path:'/recomendaciones',
      component:() => import('views/recomendaciones/index.vue'),
      children:[
         {
            path:'',
            name:'recomendacion.list',
            component:() => import('views/recomendaciones/list.vue'),
            meta:{
               resource:'recomendaciones',
               action:'read',
               pageTitle:'Tips',
            }
         },

          {
            path:'create',
            name:'recomendacion.create',
            component:() => import('views/recomendaciones/create.vue'),
            meta:{
               resource:'recomendaciones',
               action:'write',
               pageTitle:'Registrar nuevo tip',
               navActiveLink:'recomendacion.list',
               breadcrumb:[
                  {text:'Tips',to:{name:'recomendacion.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'recomendacion.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.tour.tour.id === to.params.id){
                  next()
               }else{
                  store.dispatch('recomendacion/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/recomendaciones/edit.vue'),
            meta:{
               resource:'recomendaciones',
               action:'write',
               pageTitle:'Editar Tip',
               navActiveLink:'recomendacion.list',
               breadcrumb:[
                  {text:'Tips',to:{name:'recomendacion.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },



   /*****************************************/
   /* precios 
   /*************************************** */

   {
      path:'/precios',
      component:() => import('views/precios/index.vue'),
      children:[
         {
            path:'',
            name:'precio.list',
            component:() => import('views/precios/list.vue'),
            meta:{
               resource:'precios',
               action:'read',
               pageTitle:'Precios',
            }
         },

          {
            path:'create',
            name:'precio.create',
            component:() => import('views/precios/create.vue'),
            meta:{
               resource:'precios',
               action:'write',
               pageTitle:'Registrar nuevo precio',
               navActiveLink:'precio.list',
               breadcrumb:[
                  {text:'Precios',to:{name:'precio.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'precio.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.precio.precio.id === to.params.id){
                  next()
               }else{
                  store.dispatch('precio/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/precios/edit.vue'),
            meta:{
               resource:'precios',
               action:'write',
               pageTitle:'Editar Precio',
               navActiveLink:'precio.list',
               breadcrumb:[
                  {text:'Precios',to:{name:'precio.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Cupones 
   /*************************************** */

   {
      path:'/cupones',
      component:() => import('views/cupones/index.vue'),
      children:[
         {
            path:'',
            name:'cupon.list',
            component:() => import('views/cupones/list.vue'),
            meta:{
               resource:'cupones',
               action:'read',
               pageTitle:'Cupones',
            }
         },

          {
            path:'create',
            name:'cupon.create',
            component:() => import('views/cupones/create.vue'),
            meta:{
               resource:'cupones',
               action:'write',
               pageTitle:'Registrar nuevo cupón',
               navActiveLink:'cupon.list',
               breadcrumb:[
                  {text:'Cupones',to:{name:'cupon.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'cupon.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.cupon.cupon.id === to.params.id){
                  next()
               }else{
                  store.dispatch('cupon/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/cupones/edit.vue'),
            meta:{
               resource:'cupones',
               action:'write',
               pageTitle:'Editar Cupón',
               navActiveLink:'cupon.list',
               breadcrumb:[
                  {text:'Cupones',to:{name:'cupon.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Asignaciones de cupones 
   /*************************************** */



   {
      path:'/cupones/asignaciones/',
      component:() => import('views/cupones/asignaciones/index.vue'),
      children:[
          {
            path:'',
            name:'asignacion.cupon.list',
            component:() => import('views/cupones/asignaciones/list.vue'),
            meta:{
               resource:'Asignación de cupones',
               action:'read',
               pageTitle:'Asignación de cupones',
            }
         },

         {
            path:'create',
            name:'asignacion.cupon.create',
            component:() => import('views/cupones/asignaciones/create.vue'),
            meta:{
               resource:'Asignación de cupones',
               action:'write',
               pageTitle:'Asignar Cupón',
               navActiveLink:'asignacion.cupon.list',
               breadcrumb:[
                  {text:'Asignaciones',to:{name:'asignacion.cupon.list'},active:false},
                  {text:'ASignar',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'asignacion.cupon.edit',
            component:() => import('views/cupones/asignaciones/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('asignacionCupon/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta:{
               resource:'Asignación de cupones',
               action:'write',
               pageTitle:'Editar asignación',
               navActiveLink:'asignacion.cupon.list',
               breadcrumb:[
                  {text:'Asignaciones',to:{name:'asignacion.cupon.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         },

      ]

   },

   /*****************************************/
   /* Agencias 
   /*************************************** */

   {
      path:'/agencias',
      component:() => import('views/agencias/index.vue'),
      children:[
         {
            path:'',
            name:'agencia.list',
            component:() => import('views/agencias/list.vue'),
            meta:{
               resource:'agencias',
               action:'read',
               pageTitle:'Agencias',
            }
         },

          {
            path:'create',
            name:'agencia.create',
            component:() => import('views/agencias/create.vue'),
            meta:{
               resource:'agencias',
               action:'write',
               pageTitle:'Registrar nueva agencia',
               navActiveLink:'agencia.list',
               breadcrumb:[
                  {text:'Agencias',to:{name:'agencia.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'agencia.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.agencia.agencia.id === to.params.id){
                  next()
               }else{
                  store.dispatch('agencia/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/agencias/edit.vue'),
            meta:{
               resource:'agencias',
               action:'write',
               pageTitle:'Editar Agencia',
               navActiveLink:'agencia.list',
               breadcrumb:[
                  {text:'Agencias',to:{name:'agencia.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         },
         {
            path:':id/promotores',
            component:() => import('views/agencias/promotores.vue'),
            name:'agencia.promotores',
            beforeEnter: (to, from, next) => {
               if(store.state.agencia.agencia.id === to.params.id){
                  next()
               }else{
                  store.dispatch('agencia/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },   
            meta:{
               pageTitle:'Promotores',
               resource:'agencias',
               action:'read',
               navActiveLink:'agencia.list',
               breadcrumb:[
                  {text:'Agencias', to:{name:'agencia.list'},active:false},
                  {text:'Promotores',active:true}
               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Transportistas
   /*************************************** */

   {
      path:'/transportistas',
      component:() => import('views/transportistas/index.vue'),
      children:[
         {
            path:'',
            name:'transportista.list',
            component:() => import('views/transportistas/list.vue'),
            meta:{
               resource:'transportistas',
               action:'read',
               pageTitle:'Transportistas',
            }
         },

          {
            path:'create',
            name:'transportista.create',
            component:() => import('views/transportistas/create.vue'),
            meta:{
               resource:'transportistas',
               action:'write',
               pageTitle:'Registrar nuevo transportistas',
               navActiveLink:'transportista.list',
               breadcrumb:[
                  {text:'Transportistas',to:{name:'transportista.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'transportista.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.transportista.transportista.id === to.params.id){
                  next()
               }else{
                  store.dispatch('transportista/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/transportistas/edit.vue'),
            meta:{
               resource:'transportistas',
               action:'write',
               pageTitle:'Editar Transportista',
               navActiveLink:'transportista.list',
               breadcrumb:[
                  {text:'Transportista',to:{name:'transportista.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         },
         {
            path:':id/chofers',
            component:() => import('views/transportistas/choferes.vue'),
            name:'transportista.choferes',
            beforeEnter: (to, from, next) => {
               if(store.state.transportista.transportista.id === to.params.id){
                  next()
               }else{
                  store.dispatch('transportista/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },   
            meta:{
               pageTitle:'Transportistas',
               resource:'transportistas',
               action:'read',
               navActiveLink:'transportista.list',
               breadcrumb:[
                  {text:'Transportistas', to:{name:'transportista.list'},active:false},
                  {text:'Choferes',active:true}
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Reservaciones 
   /*************************************** */

   {
      path:'/reservaciones',
      component:() => import('views/reservaciones/index.vue'),
      children:[
         {
            path:'',
            name:'reserva.list',
            component:() => import('views/reservaciones/list.vue'),
            meta:{
               pageTitle:"Reservaciones",
               resource:'reservaciones',
               action:'read',
            }
         },

          {
            path:'create',
            name:'reserva.create',
            component:() => import('views/reservaciones/create.vue'),
            meta:{
               pageTitle:"Reservar",
               resource:'reservaciones',
               action:'write',
               navActiveLink:'reserva.list',
               breadcrumb:[
                  {text:'Reservaciones',to:{name:'reserva.list'},active:false},
                  {text:'Crear', active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'reserva.edit',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('reserva/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            component:() => import('views/reservaciones/edit.vue'),
            meta:{
               pageTitle:"Editar reserva",
               resource:'reservaciones',
               action:'write',
               navActiveLink:'reserva.list',
               breadcrumb:[
                  {text:'Reservaciones',to:{name:'reserva.list'},active:false},
                  {text:'Editar', active:true},

               ]
            }
         }


      ]
   },


   /*****************************************/
   /* Restricciones de reseras 
   /*************************************** */

   {
      path:'/restricciones-reservas',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'restriccion-reserva.list',
            component:() => import('views/restricciones-reservas/lists.vue'),
            meta:{
               pageTitle:"Restricciones de reservas",
               resource:'Restricciones de reservas',
               action:'read',
            }
         },
         {
            path:'create',
            name:'restriccion-reserva.create',
            component:() => import('views/restricciones-reservas/create.vue'),
            meta:{
               pageTitle:"Crear Restricción",
               resource:'Restricciones de reservas',
               action:'write',
               navActiveLink:'restriccion-reserva.list',
               breadcrumb:[
                  {text:'Restricciones de reservas',to:{name:'restriccion-reserva.list'},active:false},
                  {text:'Crear', active:true},
               ]
            }
         },
         {
            path:':id/edit',
            name:'restriccion-reserva.edit',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('restriccionReserva/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            component:() => import('views/restricciones-reservas/edit.vue'),
            meta:{
               pageTitle:"Editar Restricción",
               resource:'Restricciones de reservas',
               action:'write',
               navActiveLink:'restriccion-reserva.list',
               breadcrumb:[
                  {text:'Restricciones de reservas',to:{name:'restriccion-reserva.list'},active:false},
                  {text:'Editar', active:true},
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* salidas 
   /*************************************** */
   {
      path:'/salidas',
      component:() => import('views/salidas/index.vue'),
      children:[
         {
            path:'',
            name:'salida.list',
            component:() => import('views/salidas/list.vue'),
            meta:{
               pageTitle:"Salidas",
               resource:'salidas',
               action:'read',
            }
         },

          {
            path:'create',
            name:'salida.create',
            component:() => import('views/salidas/create.vue'),
            meta:{
               pageTitle:"Crear Salida",
               resource:'salidas',
               action:'write',
               navActiveLink:'salida.list',
               breadcrumb:[
                  {text:'Salidas',to:{name:'salida.list'},active:false},
                  {text:'Crear', active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'salida.edit',
            component:() => import('views/salidas/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('salida/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            meta:{
               pageTitle:"Editar Salida",
               resource:'salidas',
               action:'update',
               navActiveLink:'salida.list',
               breadcrumb:[
                  {text:'Salidas',to:{name:'salida.list'},active:false},
                  {text:'Editar', active:true},
               ]
            }
         },
         {
            path:':id/info',
            name:'salida-info',
            props:true,
            component:() => import('views/perfiles/salidaHoy.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('salida/fetch',to.params.id).then(({id,status}) => {
                  if(id && [1,2,3,4].includes(status)){
                     next()
                  }
               })
            },
               meta:{
                  resource:'Salidas hoy',
                  action:'read',
               }
            }


      ]
   },

   /*****************************************/
   /* Terminos 
   /*************************************** */

   {
      path:'/terminos',
      component:() => import('views/convenios/index.vue'),
      children:[
         {
            path:'',
            name:'termino.list',
            component:() => import('views/convenios/list.vue'),
            meta:{
               resource:'Terminos',
               action:'read',
               pageTitle:'Términos',
            }
         },

          {
            path:'create',
            name:'termino.create',
            component:() => import('views/convenios/create.vue'),
            meta:{
               resource:'terminos',
               action:'write',
               pageTitle:'Registrar nuevo término',
               navActiveLink:'termino.list',
               breadcrumb:[
                  {text:'Términos',to:{name:'termino.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'termino.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.convenio.convenio.id === to.params.id){
                  next()
               }else{
                  store.dispatch('convenio/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/convenios/edit.vue'),
            meta:{
               resource:'Terminos',
               action:'write',
               pageTitle:'Editar Término',
               navActiveLink:'termino.list',
               breadcrumb:[
                  {text:'Términos',to:{name:'termino.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },

   /*****************************************/
   /* Convenios 
   /*************************************** */

   {
      path:'/convenios',
      component:() => import('views/terminos/index.vue'),
      children:[
         {
            path:'',
            name:'convenio.list',
            component:() => import('views/terminos/list.vue'),
            meta:{
               resource:'Convenios',
               action:'read',
               pageTitle:'Convenios',
            }
         },

          {
            path:'create',
            name:'convenio.create',
            component:() => import('views/terminos/create.vue'),
            meta:{
               resource:'Convenios',
               action:'write',
               pageTitle:'Registrar nuevo Convenio',
               navActiveLink:'convenio.list',
               breadcrumb:[
                  {text:'Convenios',to:{name:'convenio.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'convenio.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.termino.termino.id === to.params.id){
                  next()
               }else{
                  store.dispatch('termino/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/terminos/edit.vue'),
            meta:{
               resource:'Convenios',
               action:'write',
               pageTitle:'Editar Convenio',
               navActiveLink:'convenio.list',
               breadcrumb:[
                  {text:'Convenios',to:{name:'convenio.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Actividades 
   /*************************************** */

   {
      path:'/actividads',
      component:() => import('views/actividades/index.vue'),
      children:[
         {
            path:'',
            name:'actividad.list',
            component:() => import('views/actividades/list.vue'),
            meta:{
               resource:'actividades',
               action:'read',
               pageTitle:'Eventos en el Destino (TravelPoints)',
            }
         },

          {
            path:'create',
            name:'actividad.create',
            component:() => import('views/actividades/create.vue'),
            meta:{
               resource:'actividades',
               action:'write',
               pageTitle:'Registrar nueva actividad',
               navActiveLink:'actividad.list',
               breadcrumb:[
                  {text:'actividades',to:{name:'actividad.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'actividad.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.actividad.actividad.id === to.params.id){
                  next()
               }else{
                  store.dispatch('actividad/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/actividades/edit.vue'),
            meta:{
               resource:'actividades',
               action:'write',
               pageTitle:'Editar Actividad',
               navActiveLink:'actividad.list',
               breadcrumb:[
                  {text:'Actividades',to:{name:'actividad.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         },

            {
            path:':id/show',
            name:'actividad.show',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.actividad.actividad.id === to.params.id){
                  next()
               }else{
                  store.dispatch('actividad/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/actividades/show.vue'),
            meta:{
               resource:'actividades',
               action:'read',
               pageTitle:'',
               navActiveLink:'actividad.list',
               breadcrumb:[
                  {text:'Actividades',to:{name:'actividad.list'},active:false},
                  {text:'Actividad',active:true},

               ]
            }
         }

      ]
   },

   /*****************************************/
   /* Cuestionarios 
   /*************************************** */

   {
      path:'/cuestionarios',
      component:() => import('views/cuestionarios/index.vue'),
      children:[
         {
            path:'',
            name:'cuestionario.list',
            component:() => import('views/cuestionarios/list.vue'),
            meta:{
               resource:'cuestionarios',
               action:'read',
               pageTitle:'Cuestionarios',
            }
         },

          {
            path:'create',
            name:'cuestionario.create',
            component:() => import('views/cuestionarios/create.vue'),
            meta:{
               resource:'cuestionarios',
               action:'write',
               pageTitle:'Registrar nuevo cuestonario',
               navActiveLink:'cuestionario.list',
               breadcrumb:[
                  {text:'Cuestionarios',to:{name:'cuestionario.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },

          {
            path:':id/edit',
            name:'cuestionario.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.cuestionario.cuestionario.id === to.params.id){
                  next()
               }else{
                  store.dispatch('cuestionario/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/cuestionarios/edit.vue'),
            meta:{
               resource:'cuestionarios',
               action:'write',
               pageTitle:'Editar Cuestionario',
               navActiveLink:'cuestionario.list',
               breadcrumb:[
                  {text:'Cuestionarios',to:{name:'cuestionario.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         },

            {
            path:':id/preguntas',
            name:'cuestionario.preguntas',
            props:true,
            beforeEnter: (to, from, next) => {
               store.commit('pregunta/clear')
               if(store.state.cuestionario.cuestionario.id === to.params.id){
                  next()
               }else{
                  store.dispatch('cuestionario/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/cuestionarios/preguntas.vue'),
            meta:{
               resource:'cuestionarios',
               action:'read',
               pageTitle:'',
               navActiveLink:'cuestionario.list',
               breadcrumb:[
                  {text:'Cuestionarios',to:{name:'cuestionario.list'},active:false},
                  {text:'Preguntas',active:true},

               ]
            }
         },

         {
            path:':id/preguntas/cuestionario',
            name:'cuestionario.preguntas.show',
            props:true,
            beforeEnter: (to, from, next) => {
               if(store.state.cuestionario.cuestionario.id === to.params.id){
                  next()
               }else{
                  store.dispatch('cuestionario/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
               }
            },
            component:() => import('views/cuestionarios/cuestionario.vue'),
            meta:{
               resource:'cuestionarios',
               action:'read',
               pageTitle:'',
               navActiveLink:'cuestionario.list',
               breadcrumb:[
                  {text:'Cuestionarios',to:{name:'cuestionario.list'},active:false},
                  {text:'Cuestionario',active:true},

               ]
            }
         }


      ]
   },


    /*****************************************/
   /* Encuestas 
   /*************************************** */
   {
      path:'/encuestas',
      component:() => import('components/Views.vue'),
      
      children:[
        {
         path:'',
         name:'encuestas.list',
         component:() => import('views/encuestas/list.vue'),
         meta:{
            pageTitle:'Encuestas',
            resource:'Encuestas',
            action:'read'
         }
        }
      ]
   },

   /*****************************************/
   /* Sistema 
   /*************************************** */
   {
      path:'/sistema',
      name:'sistema',
      component:() => import('views/sistema.vue'),
      beforeEnter: (to, from, next) => {
         store.dispatch('sistema/fetch').then(({id}) => {
            if(id){
               next()
            }else{
               next({name:'home'})
            }

         }).catch(e => {
            next(({name:'home'}))
         });

      },
      meta:{
         pageTitle:'Sistema',
         resource:'sistema',
         action:'read'
      }
   },

   /*****************************************/
   /* Billetera 
   /*************************************** */

   {
      path:'/estado-cuenta',
      component:() => import('views/estado-cuentas/index.vue'),

      children:[
         {
            path:'',
            name:'billetera',
            component:() => import('views/estado-cuentas/list.vue'),
            meta:{
               pageTitle:"Estado de cuenta",
               resource:'billetera',
               action:'read'
            }
            
         }
      ]
   },

   /*****************************************/
   /* Entradas y Salidas 
   /*************************************** */
   {
      path:'/entrada-salidas',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'entrada_salidas.list',
            component: () => import('views/entrada-salidas/list.vue'),
            meta:{
               resource:'Entradas y salidas',
               pageTitle:'Ingresos y Egresos',
               action:'read'
            }
         },

         {
            path:'create',
            name:'entrada_salidas.create',
            component: () => import('views/entrada-salidas/create.vue'),
            meta:{
               resource:'Entradas y salidas',
               action:'write',
               pageTitle:'Registrar ingreso o egreso',
               navActiveLink:'entrada_salidas.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'entrada_salidas.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'entrada_salidas.edit',
            props:true,
            beforeEnter: (to,from,next) => {
               store.dispatch('entradaSalida/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            component: () => import('views/entrada-salidas/edit.vue'),
            meta:{
               resource:'Entradas y salidas',
               action:'update',
               pageTitle:'Actualizar ingreso o egreso',
               navActiveLink:'entrada_salidas.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'entrada_salidas.list'},active:false},
                  {text:'Actualizar',active:true},
               ]
            }
         }


      ]
   },


    /*****************************************/
   /* Retiros 
   /*************************************** */
  {
      path:'/mis-retiros',
      name:'perfil.retiros',
      component: () => import('views/perfiles/cartera.vue'),
      meta:{
        resource:'Retirar fondos',
        action:'read',
        pageTitle:'Retiros',
      },
   },

   /*****************************************/
   /* Retiros 
   /*************************************** */

   {
      path:'/retiros',
      component:() => import('views/pagos/index.vue'),
      children:[
         {
            path:'',
            name:'pago.list',
            component:() => import('views/pagos/lists.vue'),
            meta:{
               pageTitle:"Retiros",
               resource:'pagos',
               action:'read'
            }
         }
      ]
   },

   /*****************************************/
   /* Acreditaciones 
   /*************************************** */

   {
      path:'/acreditaciones',
      component:() => import('components/Views.vue'),

      children:[
         {
            path:'',
            name:'acreditacion.list',
            component:() => import('views/acreditaciones/list.vue'),
            meta:{
               pageTitle:"Pagos",
               resource:'Acreditaciones',
               action:'read'
            }
         }

      ]
   },


   /*****************************************/
   /* Pagos Fijos 
   /*************************************** */
   {
      path:'/pagos-fijos',
      component:() => import('views/fijos/index.vue'),
      children:[
         {
            path:'',
            component:() => import('views/fijos/list.vue'),
            name:'fijos.list',
            meta:{
               pageTitle:'Pagos Fijos',
               resource:'fijos',
               action:'read'
            }
         },

         {
            path:'create',
            component:() => import('views/fijos/create.vue'),
            name:'fijo.create',
            meta:{
               pageTitle:'Crear pago fijo',
               resource:'fijos',
               action:'write',
               navActiveLink:'fijos.list',
               breadcrumb:[
                  {text:'Fijos',to:{name:'fijos.list'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         },

         {
            path:':id/edit',
            component:() => import('views/fijos/edit.vue'),
            name:'fijo.edit',
            props:true,
            beforeEnter: (to, from, next) => {
               store.dispatch('fijo/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            meta:{
               pageTitle:'Editar pago fijo',
               resource:'fijos',
               action:'update',
               navActiveLink:'fijos.list',
               breadcrumb:[
                  {text:'Fijos',to:{name:'fijos.list'},active:false},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },

    /*****************************************/
   /* Comisiones de Perfiles
   /*************************************** */
   {
      path:'/perfil-comisions',

      component:() => import('views/perfil-comisions/index.vue'),
      children:[
         {
            path:'',
            component:() => import('views/perfil-comisions/list.vue'),
            name:'listar.comisiones.perfil',
            meta:{
               resource:'Comisiones de perfil',
               action:'read',
               pageTitle:'Comisiones de Perfiles',

            }
         },
           {
            path:'create',
            component:() => import('views/perfil-comisions/create.vue'),
            name:'create.comision.perfil',
            meta:{
               resource:'Comisiones de perfil',
               action:'write',
               pageTitle:'Registrar comisión de perfil',
               navActiveLink:'listar.comisiones.perfil',
               breadcrumb:[
                  {
                     text:'Comisiones',active:false,to:{name:'listar.comisiones.perfil'}
                  },
                  {
                     text:'Crear',active:true
                  }
               ]
            }
         },

         {
            path:':id/edit',
            component:() => import('views/perfil-comisions/edit.vue'),
            name:'edit.comision.perfil',
            props:true,
            beforeEnter: (to, from, next) => {
                  store.dispatch('PerfilComision/fetch',to.params.id).then(({id}) => {
                     if(id){
                        next()
                     }
                  })
            },
            meta:{
               resource:'Comisiones de perfil',
               action:'update',
               pageTitle:'Actualizar comisión de perfil',
               navActiveLink:'listar.comisiones.perfil',
               breadcrumb:[
                  {
                     text:'Comisiones',active:false,to:{name:'listar.comisiones.perfil'}
                  },
                  {
                     text:'Editar',active:true
                  }
               ]
            }
         }


      ]
   },

   /*****************************************/
   /* Discrepancias
   /*************************************** */
   {
      path:'/discrepancias',
      component: () => import('views/index.vue'),
      children:[
         {
            path:'',
            component:() => import('views/discrepancias/list.vue'),
            name:'discrepancia.list',
            meta:{
               resource:'Discrepancias',
               action:'read',
               pageTitle:'Discrepancias'
            }
         }
      ]
   },

   {
      path:'/pagos-negocio',
      component:() => import('views/negocios/pagos/list.vue'),
      name:'pagos.negocio.list',
      meta:{
         pageTitle:'Pagos de negocios',
         resource:'Pagos negocio',
         action:'read'
      }
      
   },


   {
      path:'/ventas/no-reportadas',
      component:() => import('views/ventas/noReportada.vue'),
      name:'negocio.ventas.noreportada',
      meta:{
         resources:'Ventas',
         action:'read',
         pageTitle:'Ventas no Reportadas',
      }
   },
   
   /*****************************************/
   /* Idiomas
   /*************************************** */

   {
      path:'/idiomas',
      component:() => import('views/idiomas/index.vue'),
      children:[
         {
            path:'',
            name:'idioma.list',
            component:() => import('views/idiomas/list.vue'),
            meta:{
               pageTitle:'Idiomas del sistema',
               resource:'idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },

         {
            path:'traduccions',
            name:'idioma.traducciones',
            props: (route) => ({ lang: route.query.lang }),
            component:() => import('views/idiomas/traducciones.vue'),
            meta:{
               pageTitle:'Traducciones del idioma',
               navActiveLink:'idioma.list',
               resource:'idiomas',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Traducciones',active:true},
               ]
            }
         },

         {
            path:'create',
            name:'idioma.create',
            component:() => import('views/idiomas/create.vue'),
            meta:{
               pageTitle:'Crear',
               resource:'idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Crear',active:true},
               ]
            }
         },

          {
            path:':id/edit',
            name:'idioma.edit',
            props:true,
            component:() => import('views/idiomas/edit.vue'),
            meta:{
               pageTitle:'Editar',
               resource:'idiomas',
               navActiveLink:'idioma.list',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'idioma.list'}},
                  {text:'Editar',active:true},
               ]
            }
         }


      ]
   },

   /*****************************************/
   /* Objetivos
   /*************************************** */

   {
      path:'/configuracion/objetivos',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name: 'objetivos.list',
            component:() => import('views/objetivos/list.vue'),
            meta: {
               pageTitle: 'Objetivos',
               resource: 'Objetivos',
               action: 'read',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
            },
         },

         {
            path:'create',
            name: 'objetivo.create',
            component:() => import('views/objetivos/create.vue'),
            meta: {
               pageTitle: 'Crear Objetivo',
               resource: 'Objetivos',
               action: 'write',
               navActiveLink:'objetivos.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'objetivos.list'}
                  },
                   {
                     text: 'Creando',
                     active: true,
                  },
               ],
            },
         },

          {
            path:':id/edit',
            props:true,
            name: 'objetivo.edit',
            component:() => import('views/objetivos/edit.vue'),
            beforeEnter:((to,from,next) => {
               store.dispatch('objetivo/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            }),
            meta: {
               pageTitle: 'Editar Objetivo',
               resource: 'Objetivos',
               action: 'update',
               navActiveLink:'objetivos.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'objetivos.list'}
                  },
                   {
                     text: 'Editando',
                     active: true,
                  },
               ],
            },
         }


      ]
      
   },

   /*****************************************/
   /* Cumplimientos
   /*************************************** */

   {
      path:'/configuracion/cumplimientos',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name: 'cumplimiento.list',
            component:() => import('views/cumplimientos/list.vue'),
            meta: {
               pageTitle: 'Montos de cumplimientos',
               resource: 'Cumplimientos',
               action: 'read',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
            },
         },

         {
            path:'create',
            name: 'cumplimiento.create',
            component:() => import('views/cumplimientos/create.vue'),
            meta: {
               pageTitle: 'Crear Cumplimiento',
               resource: 'Cumplimientos',
               action: 'write',
               navActiveLink:'cuumplimiento.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'cumplimiento.list'}
                  },
                   {
                     text: 'Creando',
                     active: true,
                  },
               ],
            },
         },

          {
            path:':id/edit',
            props:true,
            name: 'cumplimiento.edit',
            component:() => import('views/cumplimientos/edit.vue'),
            beforeEnter:((to,from,next) => {
               store.dispatch('cumplimiento/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            }),
            meta: {
               pageTitle: 'Editar Cumplimiento',
               resource: 'Cumplimientos',
               action: 'update',
               navActiveLink:'cumplimiento.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'cumplimiento.list'}
                  },
                   {
                     text: 'Editando',
                     active: true,
                  },
               ],
            },
         }


      ]
      
   },

   /*****************************************/
   /* Compras
   /*************************************** */

   {
      path:'/compras',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name: 'compra.list',
            component:() => import('views/compras/list.vue'),
            meta: {
               pageTitle: 'Compras',
               resource: 'Compras',
               action: 'read',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: true,
                  },
               ],
            },
         },

         {
            path:'create',
            name: 'compra.create',
            component:() => import('views/compras/create.vue'),
            meta: {
               pageTitle: 'Registrar Compra',
               resource: 'Compras',
               action: 'write',
               navActiveLink:'compra.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'compra.list'}
                  },
                   {
                     text: 'Creando',
                     active: true,
                  },
               ],
            },
         },

          {
            path:':id/edit',
            props:true,
            name: 'compra.edit',
            component:() => import('views/compras/edit.vue'),
            beforeEnter:((to,from,next) => {
               store.dispatch('compra/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            }),
            meta: {
               pageTitle: 'Editar Registro de Compra',
               resource: 'Compras',
               action: 'update',
               navActiveLink:'compra.list',
               breadcrumb: [
                  {
                     text: 'Listado',
                     active: false,
                     to:{name:'compra.list'}
                  },
                   {
                     text: 'Editando',
                     active: true,
                  },
               ],
            },
         }


      ]
      
   },

   /*****************************************/
   /* Chat en linea
   /*************************************** */
   {
      path:'/chat-cityfuntastic/:chatId?',
      name:'chat',
      props:true,
      component:() => import('views/chat/Chat.vue'),
      meta:{
         resource:'chat',
         action:'read',
         contentClass:'chat-application',
         contentRenderer:'sidebar-left'
      }
   },

   /*****************************************/
   /* PAGINA PARA VER PLANTILLAS DE EMAIL
   /*************************************** */

   {
      path:'/plantillas',
      component:() => import('components/Views.vue'),
      children:[
         {  
            path:'',
            name:'listar.plantillas',
            component:() => import('views/plantillas/lists.vue'),
            meta:{
               resource:'plantillas',
               action:'read',
               pageTitle:'Plantillas de email',
               breadcrumb:[
                  {
                     text:'Home',
                     to:{name:'home'},
                     active:false
                  },
                  {
                     text:'Plantillas',
                     active:true
                  }
               ]
            }
         },
         {
            path:'create',
            name:'create.plantilla',
            component:() => import('views/plantillas/create.vue'),
            meta: {
               resource: 'plantillas',
               action: 'write',
               pageTitle: 'Crear Plantilla',
               navActiveLink:'listar.plantillas',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Plantillas',
                     active: false,
                     to:{name:'listar.plantillas'},
                  },
                  {
                     text: 'Crear',
                     active: true
                  }
               ]
            }

         },
         {
            path: ':id/edit',
            name: 'edit.plantilla',
            props:true,
            component: () => import('views/plantillas/edit.vue'),
            beforeEnter: (to, from, next) => {
               store.dispatch('plantilla/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },

            meta: {
               resource: 'plantillas',
               action: 'update',
               pageTitle: 'Actualizar Plantilla',
               navActiveLink: 'listar.plantillas',
               breadcrumb: [
                  {
                     text: 'Home',
                     to: { name: 'home' },
                     active: false
                  },
                  {
                     text: 'Plantillas',
                     active: false,
                     to: { name: 'listar.plantillas' },
                  },
                  {
                     text: 'Editar',
                     active: true
                  }
               ]
            }

         }
      ]

   },


   {
      path:"/mis-cuestionarios",
      component:() => import('components/Views.vue'),
      children:[
        {
         path:'',
         name:'mis-cuestionarios',
         component:() => import('views/cuestionarios/users/list.vue'),
         meta:{
            pageTitle:'Mis Cuestionarios respondidos y por responder',
            resource:'User cuestionarios',
            action:'read',
         }

        },
        {
         path:':id/responder/:userId?',
         props:true,
         name:'mis-encuestas-responder',
         beforeEnter: (to, from, next) => {
            store.dispatch('encuesta/responderCuestionario',{enc_id:to.params.id,usuario_id:to.params.userId}).then(({result,encuesta,cuestionario}) => {
               if(result){
                  store.commit('cuestionario/setCuestionario',cuestionario)
                  store.commit('encuesta/setEncuesta',encuesta)
                  next()
               }
            })
         },
         component:() => import('views/cuestionarios/users/encuestaForm.vue'),
         meta:{
            pageTitle:'Responder encuesta',
            resource:'User cuestionarios',
            action:'read',
            navActiveLink:'mis-cuestionarios',
            breadcrumb:[
               {text:'Cuestionarios',active:false,to:{name:'mis-cuestionarios'}},
               {text:'Responder',active:true},
            ]
         }

        },

        {
         path:':id/salida/:salidaId/responder/:userId?',
         props:true,
         name:'mis-encuestas-con-salida',
         beforeEnter: (to, from, next) => {

            store.dispatch('encuesta/responderCuestionarioConSalida',{enc_id:to.params.id,salida_id:to.params.salidaId,usuario_id:to.params.userId}).then(({result,encuesta,cuestionario}) => {
               if(result){
                  store.commit('cuestionario/setCuestionario',cuestionario)
                  store.commit('encuesta/setEncuesta',encuesta)
                  next()
               }
            })
         },
         component:() => import('views/cuestionarios/users/encuestaForm.vue'),
         meta:{
            pageTitle:'Responder encuesta',
            resource:'User cuestionarios',
            action:'read',
            navActiveLink:'mis-cuestionarios',
            breadcrumb:[
               {text:'Cuestionarios',active:false,to:{name:'mis-cuestionarios'}},
               {text:'Responder',active:true},
            ]
         }

        },

        {
         path:':id/salida/:salidaId/negocio/:negocioId/responder/:userId?',
         props:true,
         name:'mis-encuestas-con-salida-y-negocio',
         beforeEnter: (to, from, next) => {

            store.dispatch('encuesta/responderCuestionarioConSalidaYNegocio',{enc_id:to.params.id,salida_id:to.params.salidaId,negocio_id:to.params.negocioId,usuario_id:to.params.userId}).then(({result,encuesta,cuestionario}) => {
               if(result){
                  store.commit('cuestionario/setCuestionario',cuestionario)
                  store.commit('encuesta/setEncuesta',encuesta)
                  next()
               }
            })
         },
         component:() => import('views/cuestionarios/users/encuestaForm.vue'),
         meta:{
            pageTitle:'Responder encuesta',
            resource:'User cuestionarios',
            action:'read',
            navActiveLink:'mis-cuestionarios',
            breadcrumb:[
               {text:'Cuestionarios',active:false,to:{name:'mis-cuestionarios'}},
               {text:'Responder',active:true},
            ]
         }

        }
      ]
   }, 


   /*****************************************/
   /* Historial de Chat
   /*************************************** */
   {
      path:'/chats/archivados',
      component:() => import('components/Views.vue'),

      children:[
         {
            path:'',
            component:() => import('views/chat/archivados.vue'),
            name:'chat.archivados',
            meta:{
               resource:'Chats archivados',
               action:'read',
               pageTitle:'Chats archivados'
            }
         },

         {
            path:':chatId/view-chat',
            component:() => import('views/chat/viewChat.vue'),
            props:true,
            name:'chat.archivados.show',
            
            meta:{
               resource:'Mensajes en el chat',
               action:'read',
               pageTitle:'Chats archivados',
               contentClass:'chat-application',
               navActiveLink:'chat.archivados',
               breadcrumb:[
                  {text:'Chats',active:false,to:{name:'chat.archivados'}},
                  {text:'Chat' ,active:true},
               ]
            }
         }

      ]
   },


   /*****************************************/
   /* Convenio de pagos (negocios)
   /*************************************** */
   {
      path:'/convenios/negocio-pagos',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'convenio.pagos.list',
            component: () => import('views/convenio-pagos/list.vue'),
            meta:{
               resource:'Convenio pagos de negocios',
               action:'read',
               pageTitle:'Convenio de pagos (negocios)'
            }
         },

         {
            path:'create',
            name:'convenio.pagos.create',
            component: () => import('views/convenio-pagos/create.vue'),
            meta:{
               resource:'Convenio pagos de negocios',
               action:'write',
               pageTitle:'Crear nuevo convenio de pago',
               navActiveLink:'convenio.pagos.list',
               breadcrumb:[
                  {text:'Convenios',to:{name:'convenio.pagos.list'},active:false},
                  {text:'Crear',active:true},

               ]
            }
         },
         {
            path:':id/edit',
            props:true,
            name:'convenio.pagos.edit',
            component: () => import('views/convenio-pagos/edit.vue'),
            beforeEnter: (to,from,next) => {
               store.dispatch('convenioPago/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            
            meta:{
               resource:'Convenio pagos de negocios',
               action:'update',
               pageTitle:'Editar convenio de pago',
               navActiveLink:'convenio.pagos.list',
               breadcrumb:[
                  {text:'Convenios',to:{name:'convenio.pagos.list'},active:false},
                  {text:'Editar',active:true},

               ]
            }
         }
      ]
   },


   /*****************************************/
   /* Pagina de cuestionarios
   /*************************************** */
   {
      path:'/cuestionarios/:slug',
      name:'cuestionario-public',
      component:() => import('views/cuestionarios/encuestaFormPublic.vue'),
      props: route => ({slug:route.params.slug, email:route.query.email}),
      beforeEnter: (to, from, next) => {
         if(to.query.email){
            store.dispatch('cuestionario/fetchDataForSlug',{slug:to.params.slug,email:to.query.email}).then(({encuesta,result}) => {
               if(result){
                  store.commit('encuesta/setEncuesta',encuesta)
                  next()
               }else{
                  next('error-404')
               }
               
            }).catch(e => {
                  next('error-404')

            })
         }else{
            toast.error('Falta el email como parametro de consulta')
         }
        
      },

      meta:{
         layout:'full',
         resource:'Auth',
         action:'read',
      }

   },


    /*****************************************/
   /* Tickets Soporte
   /*************************************** */

   {
      path:'/tickets',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'tickets.list',
            component:() => import('views/soporte/list.vue'),
            meta:{
               pageTitle:'Tickets de soporte',
               resource:'Tickets',
               action:'read'
            }
         },
         {
            path:'create',
            name:'ticket.create',
            component:() => import('views/soporte/create.vue'),
            meta:{
               pageTitle:'Crear ticket de soporte',
               resource:'Tickets',
               action:'write',
               navActiveLink:'tickets.list',
               breadcrumb:[
                  {text:'Listado',to:{name:'tickets.list'},active:false},
                  {text:'Crear',active:true}
               ]
            }
         }
      ]
   },
   /*****************************************/
   /* Reportes
   /*************************************** */
   {
      path:'/cobranza',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'cobranza',
            component:() => import('views/cobranza/list.vue'),
            meta:{
               pageTitle:'Cobranza a negocios',
               resource:'Cobranza',
               action:'read',
            }

         }
      ]
   },


   {
      path:'/reportes',
      component: () => import('components/Views.vue'),
      children:[
         {
            path:'/agencias/boletos-vendidos',
            name:'reporte.agencia.boletos.vendidos',
            component: () => import('views/reportes/agencias/boletosVendidos.vue'),
            meta:{
               resource:"Reporte de ganancias por boletos vendidos",
               action:'read',
               pageTitle:'Ganancias de agencias por Boletos Vendidos',
            }
         },

          {
            path:'ingresos',
            name:'reporte.ingresos',
            component: () => import('views/reportes/ingresos.vue'),
            meta:{
               resource:"Reporte de ingresos y egresos",
               action:'read',
               pageTitle:'Reporte de Ingresos',
            }
         },
          {
            path:'egresos',
            name:'reporte.egresos',
            component: () => import('views/reportes/egresos.vue'),
            meta:{
               resource:"Reporte de ingresos y egresos",
               action:'read',
               pageTitle:'Reporte de Egresos',
            }
         },
          {
            path:'resultados',
            name:'reporte.resultados',
            component: () => import('views/reportes/resultados.vue'),
            meta:{
               resource:"Reporte de resultados",
               action:'read',
               pageTitle:'Reporte de Resultados',
            }
         },
          {
            path:'resultados/corte-semanal',
            name:'reporte.corte-semanal',
            component: () => import('views/reportes/corteSemanal.vue'),
            meta:{
               resource:"Reporte de resultados",
               action:'read',
               pageTitle:'Reporte de Resultados (Corte semanal)',
            }
         },


         {
            path:'resultados/bono-pasajeros',
            name:'reporte.agencia.bono-pasajeros',
            component: () => import('views/reportes/agencias/bonoPasajeros.vue'),
            meta:{
               resource:"Reporte de resultados",
               action:'read',
               pageTitle:'Reporte de Bonos Pasajeros',
            }
         },

         {
            path:'resultados/comparativos',
            name:'reporte.resultados.comparativo',
            component: () => import('views/reportes/comparativo.vue'),
            meta:{
               resource:"Reporte de resultados",
               action:'read',
               pageTitle:'Reporte Comparativo',
            }
         },

         {
            path:'resultados/satisfaccion',
            name:'reporte.satisfaccion',
            component: () => import('views/reportes/satisfaccion.vue'),
            meta:{
               resource:"Reporte de satisfacción",
               action:'read',
               pageTitle:'Reporte de Satisfacción',
            }
         },

         {
            path:'transporte',
            name:'reporte.transporte',
            component: () => import('views/reportes/transporte.vue'),
            meta:{
               resource:"Reporte de transporte",
               action:'read',
               pageTitle:'Reporte de Transporte',
            }
         }


      ]
   },

   /*****************************************/
   /* Confirmación de reserva
   /*************************************** */
   {
      path:'/confirmacion-reserva/:reservaId',
      props:true,
      component:() => import('views/convenios/publicos/pasajero.vue'),
      beforeEnter:(to, from, next) => { 
         store.dispatch('reserva/fetchPublic',to.params.reservaId).then(({id}) => {
               if(id){
                  next();
               }else{
                  toast.error('La Reserva no existe')
                  // next({name:'login'})
               }
         })
      },

      name:'convenio-publico-pasajero',
      meta:{
         layout:'full',
         resource:'Auth',
         action:'read'
      }
   },


   /*****************************************/
   /* Academia
   /*************************************** */
   {
      path:'/academia',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'academia.list',
            component:() => import('views/academia/list.vue'),
            meta:{
               pageTitle:'Academia',
               resource:'academias',
               action:'read',
               breadcrumb:[
                  {text:'Listado',active:true}
               ]
            }
         },

         {
            path:'create',
            name:'academia.create',
            component:() => import('views/academia/create.vue'),
            meta:{
               pageTitle:'Academia',
               resource:'academias',
               action:'write',
               navActiveLink:'academia.list',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'academia.list'}},
                  {text:'Crear',active:true}
               ]
            }
         },

         {
            path:':id/edit',
            name:'academia.edit',
            props:true,
            beforeEnter:(from,to,next) => {
               store.dispatch('academiaVideo/fetch',from.params.id).then(({id}) => {
                  if(id){
                     next()
                  }
               }).catch(e => {
                  console.log(e)
               })
            },

            component:() => import('views/academia/edit.vue'),
            meta:{
               pageTitle:'Academia',
               resource:'academias',
               action:'update',
               navActiveLink:'academia.list',
               breadcrumb:[
                  {text:'Listado',active:false,to:{name:'academia.list'}},
                   {text:'Editar',active:true}
               ]
            }
         }
      ]
   },


   /*****************************************/
   /* PAGINA DE Mantenimiento
   /*************************************** */
   {
      path:'/mantenimiento',
      name:'show.mantenimiento',
      component:() => import('views/mantenimiento/index.vue'),
      meta:{
         layout:'full',
      }
   },
   
]