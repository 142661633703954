
export default {
  namespaced:true,

  state:() => ({
    tour:{
      id:null,
      nombre:'',
      tour_code:null,
      descripcion:'',
      descripcion_en:'',
      fecha_inicio:null,
      fecha_fin:null,
      recurrente:false,
      all_dia:false,
      privado:false,
      lugares:[],
      pickUps:[],
      pt_privado:[],
      recurrencia:{
         dia_semana:[],
          hora_inicio:null,
          hora_fin:null,
          id_group:''
      },
      
      tipo_recurrencia:1,
      status:1,
      precios:[],
      imagenes:[]
    },

    tours:[]
  }),

  getters:{

    draft:(state) => clone(state.tour),
    getStatus(state){
         return (tour) => {
            let status = ['Activo','Vencido','Aun no inicia'];

            return status[tour.status - 1];
         }
      },

      getRecurrencia(state){
         return (tour) => {
            let tipo_recurrencia = ['Diariamente','Semanalmente','Mensual','Anual'];

            return tipo_recurrencia[tour.tipo_recurrencia];
         }
      },
      
  },


  mutations:{

      clear(state){
      state.tour = {
         id:null,
         nombre:'',
         tour_code:null,
         descripcion:'',
         descripcion_en:'',
         fecha_inicio:null,
         fecha_fin:null,
         recurrente:false,
         all_dia:false,
         privado:false,
         lugares:[],
         pickUps:[],
         pt_privado:[],
         recurrencia:{
         dia_semana:[],
              hora_inicio:null,
              hora_fin:null,
              id_group:''
         },
         tipo_recurrencia:1,
         status:1,
         precios:[],
         imagenes:[]
         }
      },

      capturar(state,tour_id){
         state.tour = state.tours.find(val => val.id === tour_id)
      },

      setTour(state,tour){
         state.tour = tour
      },

      setTours:(state,tours) => {
         state.tours = tours
      },

      update(state,tour){
         let i = state.tours.findIndex(val => val.id === tour.id)

         if(i != -1){
            state.tours[i] = tour
         }

         if(state.tour.id === tour.id){
            state.tour = tour
         }

      },

      push(state,tour){
         state.tours.push(tour)
      },

      put(state,tour_id){

         state.tours.splice(
            state.tours.findIndex(val => val.id === tour_id),
            1
         )

      },

      updateImagenes(state,imagenes){
         state.tour.imagenes = imagenes || []

      },

      agregarPtPrivado(state){
         state.tour.pt_privado.push({
            'asientos': state.tour.pt_privado.length ? state.tour.pt_privado[state.tour.pt_privado.length - 1].asientos + 1 : 1,
            'precio': 0,
         })
      },
      
      quitarPtPrivado(state){
         state.tour.pt_privado.splice(
            state.tour.pt_privado.length - 1,
            1
         )
      }


  },

  actions:{
      fetch({state,commit},tour_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/tours/${tour_id}/fetch-data`).then(({data}) => {
               commit('setTour',data)
               resolve(data)
            }).catch( e => reject(e))
         })
      },


      fetchData({state,commit},datos){

         return new Promise((resolve, reject) => {
            
            axios.post(`/api/tours/fetch-data`,datos).then(({data}) => {
               commit('setTours',data.tours)
               resolve(data)
            }).catch(e => reject(e))


         })
      },

       guardar({commit},datos){

         return new Promise((resolve, reject) => {
           
            if(datos.id){
               axios.put(`/api/tours/${datos.id}`,datos).then(({data}) => {
                  resolve(data)

               }).catch( e => reject(e))

            }else{

               axios.post(`/api/tours`, datos).then(({ data }) => {
                  
                  if (data.result) {
                     commit('setTour', data.tour)
                  }
                  
                  resolve(data)

               }).catch(e => reject(e))

            }

         })
      },

      eliminar({ commit }, tour_id) {

         return new Promise((resolve, reject) => {

               axios.delete(`/api/tours/${tour_id}`).then(({ data }) => {

                  resolve(data)

               }).catch(e => reject(e))

          
         })
      },

      getListTour({commit}){
         return new Promise((resolve, reject) => {
            axios.get('/api/tours/get-list-select').then(({data}) => resolve(data)).catch(e => reject(e))
         })
      },

      lugaresTour({commit},tour_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/tours/${tour_id}/get-lugares`).then(({data}) => resolve(data)).catch(e => reject(e))
            
         })
      },
      eliminarImagen({state,commit},imagen_id){
         return new Promise((resolve, reject) => {
            axios.delete(`/api/tours/${state.tour.id}/remover-imagen/${imagen_id}`).then(({data}) => {

               if(data.result){
                  commit('updateImagenes',data.imagenes)
               }

               resolve(data)
            }).catch(e => reject(e))
         })
      },

      addImagen({commit,state},{imagenes}){

         return new Promise((resolve, reject) => {
            let formData = new FormData();

            imagenes.forEach(imagen => {
               formData.append('imagenes[]',imagen);
            });

            formData.append('tour_id',state.tour.id);

            axios.post(`/api/tours/add-imagen`,formData,{
               headers:{
                  ContentType:'multipart/form-data: boundary=something'
               }
            }).then(({data}) => {
               
               if(data.result){
                  commit('updateImagenes',data.imagenes)
               }

               resolve(data)

            })
            .catch(e => reject(e))
         })
      },


      togglePrincipal({commit,state},datos){
         return new Promise((resolve, reject) => {
            
            axios.put(`/api/tours/${state.tour.id}/toggle-principal/imagen/${datos.imagen}`,datos).then(({data}) => {
               if(data.result){
                  commit('updateImagenes',data.imagenes)
               }
               resolve(data)

            }).catch(e => reject(e))

         })
      },

      reporteToursNegocio({commit},datos){
         
         return new Promise((resolve, reject) => {
            axios.post(`/api/negocio/reportes/fetch-data-tours`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
         })

      }



  }



}