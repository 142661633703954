export default{
  namespaced:true,

  state:() => ({
    discrepancia:{
      id:null,
      observacion:'',
      imagenes:[]
    },

    discrepancias:[]
  }),


  getters:{
    draft:(state) => clone(state.discrepancia)
  },

  mutations:{
    clear(state){
      state.discrepancia = {
         id:null,
        observacion:'',
        imagenes:[]
      }
    },

    setDiscrepancia(state,discrepancia){
      if(discrepancia){
        state.discrepancia = discrepancia
      }
    }
  },

  actions:{

    fetch({commit},discrepancia_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/discrepancias/${discrepancia_id}/fetch-data`).then(({data}) => {
          commit('setDiscrepancia',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/discrepancias/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(val => {
        if(val === 'imagenes'){
          datos[val].forEach(f => {
            formData.append('imagenes[]',f)
          })
        }else{
          formData.append(val,datos[val])
        }
      })

      const headers = {
        headers:{
          ContentType:'multipart/form-data'
        }
      }

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/discrepancias/${datos.id}`,formData,headers).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/discrepancias`,formData,headers).then(({data}) => {

            if(data.result){
              commit('setDiscrepancia',data.discrepancia)
            }

            resolve(data)

          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},discrepancia_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/discrepancias/${discrepancia_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    }

  }

}