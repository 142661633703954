export default {
  namespaced:true,
  state:() =>({
    
    asignacion:{
      id:null,
      cupon_id:null,
      model_id:null,
      cantidad:1,
      cantidad_asignada:1,
      tipo_model:1,
      model:null,
      cupon:null,
    },

    asignaciones:[]

  }),

  getters:{
    draft:(state) => clone(state.asignacion)
  },



  mutations:{

    clear(state){
      state.asignacion = {
        id:null,
        cupon_id:null,
        model_id:null,
        cantidad:1,
        cantidad_asignada:1,
        tipo_model:1,
        model:null,
        cupon:null,   
      }
    },


    setAsignacion(state,asignacion){
      if(asignacion){
        state.asignacion = asignacion
      }
    },


    setAsignaciones(state,asignaciones){
      if( asignaciones){
        state.asignaciones = asignaciones
      }
    }


  },


  actions:{

    fetch({commit},asignacion_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/asignacion-cupons/${asignacion_id}/fetch-data`).then(({data}) => {

          commit('setAsignacion',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/asignacion-cupons/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/asignacion-cupons/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/asignacion-cupons`,datos).then(({data}) => {
            
            if(data.result){
              commit('setAsignacion',data.asignacion)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},asignacion_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/asignacion-cupons/${asignacion_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }



  }

}