export default {

  namespaced:true,

  state:() => ({

    sistema:{
      id:null,
      nombre:'',
      descripcion:'',
      logotipo:null,
      logotipowhite:null,
      metas:[],
      scripts:[],
      terminos_generales:null,
      cuenta:null,
      destino_travel_id:null,
      paypal:false,
      paypal_id:null,
      paypal_secret:null,
      production_paypal:false,
      iva:0,
      videos: [],
      imagenes:[],


    },



  }),


  getters:{
    draft:(state) => clone(state.sistema),

   
    saldo:(state) => {
      return state.sistema.cuenta ? state.sistema.cuenta.saldo : 0
    },

  },

  mutations:{

    setSistema: (state,sistema) => {

      if(sistema){
        state.sistema = sistema
      }
    },
    
    update:(state,sistema) => state.sistema = sistema,

  },

  actions:{


    fetch({commit}){

      return new Promise((resolve, reject) => {
        
        axios.get('/api/sistemas/fetch').then(({data}) => {
          commit('setSistema',data)
          resolve(data)
        }).catch(e => reject(e))


      })
    },

    fetchPublic({state,commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`api/get/data-sistema`).then(({data}) => {
          commit('setSistema',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    guardar:({state,commit},datos) => {

      return new Promise((resolve, reject) => {
        
        axios.put(`/api/sistemas/${state.sistema.id}`, datos).then(({ data }) => {

          if (data.result) {
            commit('setSistema', data.sistema)
          }
          resolve(data)

        }).catch(e => reject(e))
      })
    

    },

    guardarLogo({commit,state},datos){
      

      return  new Promise((resolve, reject) => {
        let formData = new FormData();

        formData.append('logotipo',datos.logotipo)
        formData.append('logoName',datos.logoName)

        axios.post(`/api/sistemas/upload-logo`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
        }).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },



    crearCuenta({commit,state}){
      return new Promise((resolve, reject) => {
        
        axios.get('/api/sistemas/crear/cuenta').then(({data}) => {
          if(data.result){
            commit('setSistema',data.sistema)
          }
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    reporteComparativo({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/sistemas/reportes/reporte-comparativo`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    reporteSatisfaccion({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/sistemas/reportes/reporte-satisfaccion`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    reporteTransporte({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/sistemas/reportes/reporte-transporte`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      });
    },

    cargarArchivo({commit,state},file){

      let formData = new FormData();

      file.forEach(val => {
        formData.append('archivos[]',val);
      })

      formData.append('model_id',state.sistema.id)

      return new Promise((resolve, reject) => {
        axios.post(`/api/sistema/upload/archivos`,formData,{
          headers:{
            ContentType:'multipart/form-data',
          }
        }).then(({data}) => {

          if(data.result){
            commit('update',data.sistema)
          }

          resolve(data)

        }).catch( e => reject(e))
      })

    },

    eliminarArchivo({state,commit},datos){

      return new Promise((resolve, reject) => {
        axios.put(`/api/sistemas/${state.sistema.id}/eliminar/archivo`,datos).then(({data}) => {

          if(data.result){
            commit('update',data.sistema)
          }

          resolve(data)

        }).catch(e => reject(e))
      })
    },




  }
}