import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Import Modulos

import usuario from './modules/usuario';
import rol from './modules/configuracion/rol.js';
import permiso from './modules/configuracion/permiso.js';
// 
import notificacion from './modules/notificaciones.js';

// Pais , Estados y Municipios
import pais from './modules/pais.js'
import estado from './modules/estado.js'
import ciudad from './modules/ciudad.js'

// Idioma 
import idioma from './modules/idioma.js'

// Negocio

import negocio from './modules/negocio.js'


// Proveedor

import proveedor from './modules/proveedor.js'


// Comision

import comision from './modules/comision.js';

// Datos de Pago
import datosPago from './modules/datosPago.js'

// invitaciones
import invitacion from './modules/invitacion.js'

// tour 
import tour from './modules/tour.js';

// Lugares Turisticos
import lugarTuristico from './modules/lugarTuristico.js'

// Pickup
import pickup from './modules/pickup.js'


// Recomendacion
import recomendacion from './modules/recomendacion'

// PRecios
import precio from './modules/precio.js'

// Cupones
import cupon from './modules/cupon.js'

// Asignacion de Cupons
import asignacionCupon from './modules/asignacionCupon.js'


// Agencia
import agencia from './modules/agencia.js'

// Reserva
import reserva from './modules/reserva.js'

// Salida
import salida from './modules/salida.js'

// Comentarios de Salidas
import comentario from './modules/comentario.js'


// Transportista
import transportista from './modules/transportista.js'

// Convenio
import convenio from './modules/convenio.js'

// actividad
import actividad from './modules/actividad.js'

// cuestionario
import cuestionario from './modules/cuestionario.js'

// pregunta
import pregunta from './modules/pregunta.js'

// Sistema
import sistema from './modules/sistema.js'

// movimiento
import movimiento from './modules/movimiento.js'
import cuenta from './modules/cuenta.js'

// Pago
import pago from './modules/pago.js'

// Fijo
import fijo from './modules/fijo.js'

// Comisiones de perfil

import PerfilComision from './modules/PerfilComision.js'

// discrepancia

import discrepancia from './modules/discrepancia.js'

// PagoNegocio
import pagoNegocio from './modules/pagoNegocio.js';

// respuesta 
import respuesta from './modules/respuesta.js';

// chat
import chat from './modules/chat.js'

// mensaje
import mensaje from './modules/mensaje.js'

// Plantilla de email

import plantilla from './modules/plantilla.js';

// Encuesta

import encuesta from './modules/encuesta.js'

// Objetivos
import objetivo from './modules/objetivo.js'

// Cumplimiento
import cumplimiento from './modules/cumplimiento.js'
// Compra
import compra from './modules/compra.js'


// Termino
import termino from './modules/termino.js'

// ticketsVendidos
import ticketsVendidos from './modules/ticketsVendidos.js'

// entradaSalida
import entradaSalida from './modules/entradaSalida.js'

// ConvenioPago 
import convenioPago from './modules/convenioPago.js'

// Acreditacions 
import acreditacion from './modules/acreditacion.js'

// AcademiaVideo
import academiaVideo from './modules/academiaVideo.js'

// RestriccionReserva 
import restriccionReserva from './modules/restriccionReserva.js'

// ticket 
import ticket from './modules/ticket.js'


// panel
import panel from './modules/panel.js';


Vue.use(Vuex)

export default new Vuex.Store({
  
	state: {
		errors: {},
		loading: false,
    token:null,
    canales:[],
    auth:{
      message:null
    }
	},

	mutations: {

		cerrarApp(state) {
			state.usuario = null
		},

		toggleLoading(state,bol = null) {
			state.loading = (bol != null) ? bol : !state.loading
		},

		setError(state, data) {

      
      if (typeof data === 'string'){
        state.errors.message = data;
      }else{
        state.errors = data;
      }

		},

		clearErrors(state) {

			state.errors = {
				carta: {}
			}

		},
    
    setAuthMessage(state,data){
      state.auth.message = data;

    },

    setToken(state,token){
      state.token = token
    },

    pushCanal(state,canal){
      state.canales.push(canal)
    }


	},

	actions: {

    async cerrarSesion({ state, commit }) {
      
      return await axios.get('/api/auth/logout',null,{
        headers: {
          'WWW-Authenticate': 'Bearer', 'Authorization': (state.token) ? state.token : localStorage.getItem('accessToken')
        }
      });

    }

	},
  
  modules: {
    app,
    appConfig,
    verticalMenu,
    usuario,
    rol,
    notificacion,
    permiso,
    pais,
    estado,
    ciudad,
    idioma,
    negocio,
    comision,
    proveedor,
    datosPago,
    invitacion,
    tour,
    lugarTuristico,
    pickup,
    recomendacion,
    precio,
    cupon,
    agencia,
    asignacionCupon,
    reserva,
    salida,
    transportista,
    convenio,
    actividad,
    cuestionario,
    pregunta,
    sistema,
    movimiento,
    cuenta,
    pago,
    comentario,
    fijo,
    PerfilComision,
    discrepancia,
    pagoNegocio,
    respuesta,
    chat,
    mensaje,
    plantilla,
    encuesta,
    objetivo,
    cumplimiento,
    compra,
    termino,
    ticketsVendidos,
    entradaSalida,
    convenioPago,
    acreditacion,
    academiaVideo,
    restriccionReserva,
    ticket,
    panel
  },

  strict:false,

})
