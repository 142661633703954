const api_travel = import.meta.env.VITE_API_TRAVELPOINTS;

export default {
  namespaced:true,
  state:() =>({
    
    actividad:{
      id:null,
      actividad:'',
      nombre:null,
      model_id:null,
      model_type:null,
      usuario_id:null,
      usuario:null

    },

    actividades:[]

  }),

  getters:{
    draft:(state) => clone(state.actividad)
  },



  mutations:{

    clear(state){
      state.actividad = {
        id:null,
        actividad:'',
        nombre:null,
        model_id:null,
        model_type:null,
        usuario_id:null,
        usuario:null
      }
    },


    setActividades(state,actividads){
      if(actividads){
        state.actividades = actividads
      }
    },


    setActividad(state,actividad){
      if(actividad){
        state.actividad = actividad
      }
    }


  },


  actions:{

    fetch({commit},actividad_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/actividads/${actividad_id}/fetch-data`).then(({data}) => {

          commit('setActividad',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/actividads/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/actividads/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/actividads`,datos).then(({data}) => {
            
            if(data.result){
              commit('setActividad',data.actividad)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},actividad_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/actividads/${actividad_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

     fetchDataPublic({commit},filtro){

         return new Promise((resolve, reject) => {
            
            axios.post(`${api_travel}/api/eventos/fetch-data-public`,filtro).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))

         })
      },


      fetchEventPublic({commit},event_url){
         return new Promise((resolve, reject) => {
            axios.get(`${api_travel}/api/eventos/fetch-data/url/${event_url}`).then(({data}) => {

               if(data.result){
                  commit('setEvento',data.evento)
               }

               resolve(data)
            })
            .catch(e => reject(e))

         })
      }





  }

}