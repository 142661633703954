
export default {
  namespaced:true,
  state:() =>({
    
    salida:{
      id:null,
      tour_code:null,
      tour_id:null,
      fecha_tour:null,
      coordinador_id:null,
      travelier_id:null,
      transportista_id:null,
      status:1, // [1 => 'Activo', 2 => 'En Ruta', 3 => 'Completado' , 4 => 'Cancelado'];
      observacion:'',
      personas:1,
      travelier:null,
      chofer:null,
      coordinador:null,
      itinerario:[],
      reservas:[],
      transportista:null,
      travelier_confirmado:false,
      propinas_travelier:0,
      tipo_unidad:'',
      asientos:0,
      visitas:[]

    },

    salidas:[],
    salidasHoy:[],

    statuses:[
      {text:'Asignado',value:1,color:'success'},
      {text:'Ruta',value:2,color:'warning'},
      {text:'Completado',value:3,color:'primary'},
      {text:'Cancelado',value:4,color:'danger'},
    ]
  }),

  getters:{
    draft:(state) => clone(state.salida)
  },



  mutations:{

    clear(state){
      state.salida = {
        id:null,
        tour_code:null,
        tour_id:null,
        fecha_tour:null,
        coordinador_id:null,
        travelier_id:null,
        transportista_id:null,
        status:1, // [1 => 'Activo', 2 => 'En Ruta', 3 => 'Completado' , 4 => 'Cancelado'];
        observacion:'',
        personas:1,
        travelier:null,
        chofer:null,
        coordinador:null,
        itinerario:[],
        reservas:[],
        transportista:null,
        travelier_confirmado:false,
        propinas_travelier:0,
        visitas:[],
        tipo_unidad:'',
        asientos:0,
      }
    },


    setSalidas(state,salidas){
      if(salidas){
        state.salidas = salidas
      }
    },

    setSalidasHoy(state,salidas){
      if(salidas){
        state.salidasHoy = salidas
      }
    },



    setSalida(state,salida){
      if( salida){
        state.salida = salida
      }
    },

    establecerPropinas(state,{salida_id,propinas}){
      if(state.salida.id === salida_id){
        state.salida.propinas_travelier = propinas
      }
    },

    pushLugar(state,lugar){
      state.salida.itinerario.push(lugar)
    },

    setItinerario(state,itinerario){
      state.salida.itinerario = itinerario
    }

  },


  actions:{

    fetch({commit},salida_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/fetch-data`).then(({data}) => {
          commit('setSalida',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/salidas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/salidas/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/salidas`,datos).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))
        }
      })
    },

    eliminar({state,commit},salida_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/salidas/${salida_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },
    changeStatus({commit},datos){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${datos.id}/change-status/${datos.status}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    agregarPasajero({commit},{salida_id,reserva_id}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/agregar-pasajero/${reserva_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    },

    sacarPasajero({commit},{salida_id,reserva_id}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/sacar-pasajero/${reserva_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    },

    cargarDiasAsignados({commit},{rol,model_id}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/usuarios/${model_id}/rol/${rol}/salidas-asignadas`).then(({data}) => {
          resolve(data)
        }).catch(e  => reject(e))
      })
    },

    confirmarAsistenciaTravelier({commit},salida_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/confirmar-travelier`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))


      })
    },

    salidasHoy({commit},usuario_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/hoy/usuario/${usuario_id}`).then(({data}) => {
          commit('setSalidasHoy',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },
    
    iniciarTour({commit},salida_id){
      return new Promise((resolve,reject) => {
        axios.get(`/api/salidas/${salida_id}/aperturar-tour`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    cargarPasajeros({commit},salida_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/cargar-pasajeros`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    terminarTour({commit},salida_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/completar-tour`).then(({data}) => {
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    establecerPropinas({commit},{salida_id,propinas}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/salidas/${salida_id}/establecer-propinas`,{propinas}).then(({data}) => {
          if(data.result){
            commit('establecerPropinas',{salida_id,propinas})
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    salidasAsignadasFetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/salidas/asignadas/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    cargarSalidasHoyNegocio({commit},{encargado_id}){

      return new Promise((resolve, reject) => {
        axios.get(`/api/negocios/encargado/${encargado_id}/salidas-hoy`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    } ,

    fetchSalidaValidacionNegocio({commit},validacion_id){

      return new Promise((resolve, reject) => {
        
        axios.get(`/api/validacions/${validacion_id}/fetch-data`).then(({data}) => resolve(data))
        .catch(e => reject(e))

      })
    } ,


    
    validacionNegocio({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/salidas/${datos.salida_id}/validation-negocio`,datos).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },

    reportarVenta({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/ventas`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e => {
          reject(e);
        }))

      })
    },

     getValidaciones({commit},salida_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/get-validaciones-negocios`).then(({data}) => resolve(data))
        .catch(e => reject(e))

      })
     },

     confirmarValidacionNegocio({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/salidas/${datos.salida_id}/confirmar/validacion-negocio-por-travelier`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
     },

     rechazarValidacionNegocio({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/salidas/${datos.salida_id}/rechazar/validacion-negocio-por-travelier`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
     },
      negociosVisitados({commit},salida_id){
      return new Promise((resolve, reject) => {
          
        axios.get(`/api/salidas/${salida_id}/get/negocios-visitados`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
     },

     negociosConVentas({commit},salida_id){
      return new Promise((resolve, reject) => {
          
        axios.get(`/api/salidas/${salida_id}/get/negocios-con-ventas`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
     },


     fetchVentasNoReportadas({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/salidas/ventas-no-reportadas/fetch-data`,datos).then(({data}) => {
        
          resolve(data)
        
        }).catch(e => {
          
          reject(e)

        })
      })
     },

     marcarNegocioVisitado({commit},{salida_id,negocio_id}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/visitas/negocios/${negocio_id}/visitado`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
     },

     marcarNegocioConVenta({commit},{salida_id,negocio_id}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/compras/negocios/${negocio_id}/con-compra`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
     },


     cargarPickUps({commit},salida_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/get/pickups`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
     },

     cargarLugares({commit},salida_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/get/lugares`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
     },

     guardarLike({commit},datos){
      return new Promise((resolve,reject) => {
        axios.put(`/api/salidas/${datos.salida_id}/guardar/like`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
        
      })

     },

     establecerTravelier({commit},{salida_id,travelier_id}){
        return new Promise((resolve, reject) => {
          axios.put(`/api/salidas/${salida_id}/establecer-travelier`,{travelier_id}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
        })
     },

      establecerTransportista({commit},{salida_id,transportista_id}){
        return new Promise((resolve, reject) => {
          
          axios.put(`/api/salidas/${salida_id}/establecer-transportista`,{transportista_id}).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))


        })
     },

     pushLugar({commit},{salida_id,form}){
      
      return new Promise((resolve, reject) => {
        axios.put(`/api/salidas/${salida_id}/agregar-lugar`,form).then(({data}) => {
          if(data.result){
            commit('setItinerario',data.itinerario)
          }

          resolve(data)
        }).catch(e => reject(e))
      })
     },



     getEncuestaTransporte({commit},salida_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/salidas/${salida_id}/get/encuesta-evaluacion-transporte`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
     },

     reporteIngresoSemanalTravelier({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/salidas/reportes/travelier/ingresos-semanal`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
     },
     cargarComisionesTravelier({commit},{travelier_id,tour_code}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/tour-code/${tour_code}/travelier/${travelier_id}/get-comisiones`).then(({data}) => {
          resolve(data)
        }).catch(e =>  reject(e))
      })
     },

     resultadoEvaluacionEncuestas({commit},salida_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/resultado/evaluacion-encuestas`,salida_id)
        .then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
     },


     verDetallesDia({commit},fecha){
      return new Promise((resolve, reject) => {
        axios.post(`/api/reportes/salidas/dia/fetch-data-details`,{fecha}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
     }

   
  }

}