<template>




  <div class="auth-wrapper auth-v1 ">

    <div class="auth-inner ">
      <!-- Login v1 -->
      <b-card body-class="section-form-login">
        <logo :url="{ name: 'inicio' }" class="logo-auth-1" />


        <b-card-title class=" text-center font-weight-bolder">
        </b-card-title>

        <h4 class=" text-center">Inicia sesión en tu cuenta y comienza la aventura 👋</h4>

        <!-- form -->
        <validation-observer ref="formValidate" #default="{ invalid, handleSubmit }" class="validation-observer-auth-1">
          <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(iniciar)">


            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider #default="{ errors }" name="email" rules="required|email">
                <b-form-input id="email" v-model="formulario.email" name="login-email"
                  :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password' }" router-tag="a">
                  Olvidaste la contraseña?
                </b-link>
              </div>
              <validation-provider #default="{ errors }" name="password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="formulario.password" :type="passwordFieldType"
                    class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password"
                    placeholder="Password" />

                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox id="remember-me" v-model="formulario.remember" name="checkbox-1">
                Recordarme
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" type="submit" block :disabled="invalid" v-loading="loading">
              Iniciar
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BFormInvalidFeedback
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { computed, toRefs, onMounted,inject } from 'vue';
import useAuth from '@core/utils/useAuth'

import '@core/scss/vue/libs/toastification.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    BCard,
    ValidationObserver,
    BFormInvalidFeedback,
    Logo: () => import('components/Logo.vue')
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: import('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = import('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },



  setup(props) {

    const usuario = computed(() => store.state.usuario.usuario)
    const { appName, appLogoImage, applogoImageWhite } = $themeConfig.app
    const i18n  = inject('i18n')
    const {
      
      login,
      formValidate,
      formulario,
      authGoogle,
      authenticarGoogle,
      optionsAuth
    } = useAuth();

    onMounted(() => localStorage.removeItem('destino_id'))

    const iniciar = () => {


      login().then((result) => {

        if (result) {

          router.replace(getHomeRouteForLoggedInUser(usuario.value.rol.nombre)).then(
            () => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `${i18n.t('Hola')} ${usuario.value.nombre || usuario.value.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: i18n.t(`Has iniciado sesión correctamente como`) + ' ' + usuario.value.rol.nombre + '. ' + i18n.t(`¡Ahora puedes empezar a explorar!`),
                },
              }, {
                position: 'bottom-right',
                timeout: 4000
              })
            })

        } else {

          toast({
            component: ToastificationContent,
            props: {
              title: `No pudimos autenticarte, inténtelo de nuevo`,
              icon: 'HelpCircleIcon',
              variant: 'danger',
            },
          }, {
            position: 'bottom-right',
            timeout: 4000
          })

        }


      }).catch(e => {

        if (e.response.status === 401) {
          if (!e.response.data.result) {
            toast.info(e.response.data.message)
          }

        }

      })
    }

    return {
      login,
      required,
      loading: computed(() => store.state.loading),
      auth: computed(() => store.state.auth),
      formValidate,
      formulario,
      iniciar,
      authGoogle,
      appName,
      authenticarGoogle,
      optionsAuth


    }
  }


}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo img {
  width: 220px;
  height: auto;
}

.video-travel {

  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  video {
    height: 100%;
    width: 100%;
  }
}

// video::-webkit-media-controls {
//   display: none !important;
// }

// video::-webkit-media-controls-enclosure {
//   display: none !important;
// }

// video::-webkit-media-controls-panel {
//   display: none !important;
// }
</style>