export default{

   namespaced:true,

   state(){
         return {
            plantilla:{
               id:null,
               contenido:'',
               contenido_en:'',
               disparador:null,
               rol_id:null,
               rol:null,
               path:'',
               path_en:'',
               tema:'',
               activo:true
            },

            plantillas:[],
            disparadores: [
               {
                  label: 'Al registrar nuevo usuario',
                  value: 1,
                  variables: ['nombre','apellido','email','telefono','rol_name'],
               },
               {
                  label: 'Despues de una reserva',
                  value: 2,
                  variables: [
                    'pasajero_nombre',
                    'pasajero_apellido',
                     'pasajero_telefono',
                     'pasajero_email',
                    'tour_nombre',
                    'tour_tour_code',
                    'tour_descripcion',
                   'usuario_nombre',
                    'usuario_apellido',
                     'usuario_telefono',
                     'usuario_email',
                    'fecha',
                    'nombre',
                    'email',
                    'telefono',
                    'adultos',
                    'menores',
                    'infantes',
                    'promo_code',
                    'total_promo',
                    'deposito',
                    'balance',
                    'sub_total',
                    'total_reserva',
                    'observacion',
                    'estatus', // 1 => activa, 2 => Show  , 3 => No show , 4 Cancelada
                    'pickup_nombre',
                    'pickup_observacion',
                     'pickup_hora',
                    'salida_tour_code',
                    'salida_fecha_tour',
                    'salida_observacion',
                    'salida_personas',
                    'agencia_nombre',
                    'codigo_confirmacion',
                    'terminos_aceptados',
                    'coordinador_nombre',
                    'coordinador_apellido',
                    'coordinador_telefono',
                    'reserva_id',
                     'destinatario_email',
                     'destinatario_nombre_completo',
                  ],
               },
               {
                  label: 'Al Registrar una salida',
                  value: 3,
                  variables: [
                      'tour_code',
                      'tour_nombre',
                     'tour_tour_code',
                     'tour_descripcion',
                      'fecha_tour',
                      'coordinador_nombre',
                      'coordinador_apellido',
                      'coordinador_telefono',
                      'coordinador_email',
                      'travelier_nombre',
                      'travelier_apellido',
                      'travelier_telefono',
                      'travelier_email',
                        'transportista_nombre',
                      'transportista_apellido',
                      'transportista_telefono',
                      'transportista_email',
                      'status',
                      'observacion',
                      'personas',
                      'travelier_confirmado',
                       'destinatario_email',
                      'destinatario_nombre_completo',
                  ],
               },
               {
                  label: 'Al Aceptar una salida por parte del Travelier',
                  value: 4,
                  variables: [
                     'tour_code',
                      'tour_nombre',
                     'tour_tour_code',
                     'tour_descripcion',
                      'fecha_tour',
                      'coordinador_nombre',
                      'coordinador_apellido',
                      'coordinador_telefono',
                      'coordinador_email',
                      'travelier_nombre',
                      'travelier_apellido',
                      'travelier_telefono',
                      'travelier_email',
                        'transportista_nombre',
                      'transportista_apellido',
                      'transportista_telefono',
                      'transportista_email',
                      'status',
                      'observacion',
                      'personas',
                      'travelier_confirmado',,
                       'destinatario_email',
                      'destinatario_nombre_completo',
                  ]
               },
               {
                  label: 'Al Terminar un Tour',
                  value: 5,
                  variables: [
                      'tour_code',
                      'tour_nombre',
                     'tour_tour_code',
                     'tour_descripcion',
                      'fecha_tour',
                      'coordinador_nombre',
                      'coordinador_apellido',
                      'coordinador_telefono',
                      'coordinador_email',
                      'travelier_nombre',
                      'travelier_apellido',
                      'travelier_telefono',
                      'travelier_email',
                     'transportista_nombre',
                      'transportista_apellido',
                      'transportista_telefono',
                      'transportista_email',
                      'status',
                      'observacion',
                      'personas',
                      'travelier_confirmado',
                      'destinatario_email',
                      'destinatario_nombre_completo',

               
                  ],
               },

               {
                  label: 'Al reportar una venta',
                  value: 6,
                  variables: [
                      'monto',
                      'reportado',
                      'negocio_nombre',
                      'is_countable',
                      'cantidad',
                      'producto',
                      'salida_tour_code',
                      'salida_tour_nombre',
                     'salida_tour_tour_code',
                     'salida_tour_descripcion',
                      'salida_fecha_tour',
                      'salida_coordinador_nombre',
                      'salida_coordinador_apellido',
                      'salida_coordinador_telefono',
                      'salida_coordinador_email',
                      'salida_travelier_nombre',
                      'salida_travelier_apellido',
                      'salida_travelier_telefono',
                      'salida_travelier_email',
                     'salida_transportista_nombre',
                      'salida_transportista_apellido',
                      'salida_transportista_telefono',
                      'salida_transportista_email',
                      'salida_status',
                      'salida_observacion',
                      'salida_personas',
                      'salida_travelier_confirmado',
                       'destinatario_email',
                      'destinatario_nombre_completo',
                      
                  ],
               },

               {
                  label: 'Al Validar pasajeros por parte del negocio',
                  value: 7,
                  variables: [
                      'adultos',
                      'menors',
                      'validado',
                      'negocio_nombre',
                       'salida_tour_code',
                      'salida_tour_nombre',
                     'salida_tour_tour_code',
                     'salida_tour_descripcion',
                      'salida_fecha_tour',
                      'salida_coordinador_nombre',
                      'salida_coordinador_apellido',
                      'salida_coordinador_telefono',
                      'salida_coordinador_email',
                      'salida_travelier_nombre',
                      'salida_travelier_apellido',
                      'salida_travelier_telefono',
                      'salida_travelier_email',
                        'salida_transportista_nombre',
                      'salida_transportista_apellido',
                      'salida_transportista_telefono',
                      'salida_transportista_email',
                      'salida_status',
                      'salida_observacion',
                      'salida_personas',
                      'salida_travelier_confirmado',
                       'destinatario_email',
                      'destinatario_nombre_completo',
                  ],
               },
                {
                  label: 'Al validar visita al negocio por parte del Travelier',
                  value: 8,
                  variables: [
                        'tour_code',
                      'tour_nombre',
                     'tour_tour_code',
                     'tour_descripcion',
                      'fecha_tour',
                      'coordinador_nombre',
                      'coordinador_apellido',
                      'coordinador_telefono',
                      'coordinador_email',
                      'travelier_nombre',
                      'travelier_apellido',
                      'travelier_telefono',
                      'travelier_email',
                        'transportista_nombre',
                      'transportista_apellido',
                      'transportista_telefono',
                      'transportista_email',
                      'status',
                      'observacion',
                      'personas',
                      'travelier_confirmado',
                        'negocio_nombre',
                         'destinatario_email',
                      'destinatario_nombre_completo',
                  ],
               }


            ]

         }

   },


   getters:{
      draft(state){
         return clone(state.plantilla)
      },

      getNombreDisparador(state){
         return (disparador) => {
            return state.disparadores.find(val => val.value === disparador).label
         }
      }
   },

   mutations:{

      clear(state){
         state.plantilla = {
              id:null,
               contenido:'',
               contenido_en:'',
               disparador:null,
               rol_id:null,
               rol:null,
               path:'',
               path_en:'',
               tema:'',
               activo:true
         }

      },

      setPlantillas(state,data){

         state.plantillas = data

      },

      push(state,plantilla) {

         state.plantillas.push(plantilla)
      },

      put(state,plantilla_id){
         state.plantillas.splice(
            state.plantillas.findIndex(val => val.id === plantilla_id),
            1
         )
      },

      update(state,plantilla){

         let i = state.plantillas.findIndex(val => val.id === plantilla.id)

         if(i != -1 ){

            state.plantillas[i] = plantilla

            if(state.plantilla.id === plantilla.id){
               state.plantilla = plantilla
            }

         }

      },

      capturar(state,plantilla_id){

         let plant  = state.plantillas.find(val => val.id === plantilla_id)

         if(plant){
            state.plantilla = plant         
         }

      },

      setPlantilla(state,data){
         if(data){
            state.plantilla = data
         }
      }

   },

   actions:{

      fetch({commit},plantilla_id){
         return new Promise((resolve, reject) => {
            axios.get(`/api/plantillas/${plantilla_id}/fetch-data`).then(({data}) => {
               commit('setPlantilla',data)
               resolve(data)
            }).catch(e => reject(e))
         })
      },

      fetchPlantillas({state,commit},searhQuery){

         return new Promise((resolve, reject) => {

            axios.post(`/api/plantillas/fetch-data`,searhQuery).then(({data}) => {
               commit('setPlantillas',data.plantillas)
               resolve(data)
            }).catch(e => {
               reject(e) 
            })


         })
      },

      guardar({state,commit},data){
          
            return new Promise((resolve, reject) => {

               if (state.plantilla.id) {
                  axios.put(`/api/plantillas/${data.id}`, data).then(({ data }) => {

                     resolve(data)

                  }).catch(e => reject(e))
                    
               }else{

                  axios.post(`/api/plantillas`,data).then(({data}) => {
                     resolve(data)
                  }).catch(e => reject(e))
                  

               }

              

            })

         

      },


      async getTemplate({state,commit},disparador){
         return  await axios.get(`/api/plantillas/get/disparador/${disparador}`)

      },

      getPlantilla({state,commit},plantilla_id){
         

         axios.get(`/api/plantillas/get/${plantilla_id}`).then(({data}) => {
            commit('push',data)
         }).catch(e => console.log(e))
         

      },

      eliminar({state,commit},plantilla_id){
         return axios.delete(`/api/plantillas/${plantilla_id}`)
      },

      enviarEmail({commit},data){

         return new Promise((resolv,reject) => {
            
            axios.post(`/api/plantillas/send/email`,data).then(({data}) => {
               resolv(data);
            }).catch(e => reject(e))
            


         })

      }




      
   }



}
