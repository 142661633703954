//      * INDICADORES
//      *  1 => Recursos Humanos; Satisfaccion (clima laboral)
//      *  2 => Recursos Humanos; competencia
//      *  3  => recursos Humanos; Cumplimiento
//      *  4 => Operacion; Equipamiento
//      *  5 => Operacion; Salidas por mes
//      *  6 => Operacion; pasajeros por mes
//      *  7 => Operacion; tours privado
//      *  8 => operacion; uniformidad.
//      *  9 => operación; Ocupacion
//      *  10 => Agencias; Cantidad
//      *  11 => Agencias; Participacion
//      *  12 => Ingresos; Cumplimiento
//      *  13 => Ingresos; Cobranza
//      *  14 => Egresos; Cumplimiento 
//      *  15 => Aportación al destino; Gasto turístico
//      *  16 => Aportación al destino; Gasto por pasajero
//      *  17 => Calidad; Satisfacción del pasajero
//      *  18 => calidad; Satisfacción del Negocio
//      *  19 => calidad; Satisfacción del Promotor
//      *  20 => Calidad; Desempeño del Negocio
//      *  21 => Calidad; Desempeño Transportista
//      * 
//      * TIPO OBJETIVO
//      *  1 => Porcentaje
//      *  2 => Cantidad
//      *  3 => Monto
//      * TIPO MEDICION
//      *  1 => Anual
//      *  2 => Mensual
//      *  3 => Semanal
//      *  4 => Histórico
//      

export default{
  namespaced:true,

  state:() => ({
    objetivo:{
      id:null,
      nombre:'',
      descripcion:null,
      indicador:1,
      tipo_objetivo:1,
      tipo_medicion:1,
      objetivo:0
    },

    objetivos:[],
    indicadores:[
      {label:'Recursos Humanos; Satisfaccion (clima laboral)',value:1},
      {label:'Recursos Humanos; competencia',value:2},
      {label:'Recursos Humanos; Cumplimiento',value:3},
      {label:'Operación; Equipamiento',value:4},
      {label:'Operación; Salidas por mes',value:5},
      {label:'Operación; Pasajeros por mes',value:6},
      {label:'Operación; Tours privado',value:7},
      {label:'Operación; Uniformidad',value:8},
      {label:'Operación; Ocupación',value:9},
      {label:'Agencias; Cantidad',value:10},
      {label:'Agencias; Participación',value:11},
      {label:'Ingresos; Cumplimiento',value:12},
      {label:'Ingresos; Cobranza',value:13},
      {label:'Egresos; Cumplimiento',value:14},
      {label:'Aportación al destino; Gasto turístico',value:15},
      {label:'Aportación al destino; Gasto por pasajero',value:16},
      {label:'Calidad; Satisfacción del pasajero',value:17},
      {label:'Calidad; Satisfacción del Negocio',value:18},
      {label:'Calidad; Satisfacción del Promotor',value:19},
      {label:'Calidad; Desempeño del Negocio',value:20},
      {label:'Calidad; Desempeño Transportista',value:21},

    ],
    tipoObjetivos:[
      {text:'Porcentaje',value:1},
      {text:'Cantidad',value:2},
      {text:'Monto',value:3},

    ],
     tipoMedicions:[
      {text:'Anual',value:1},
      {text:'Mensual',value:2},
      {text:'Semanal',value:3},
      {text:'Histórico',value:4},

    ]
  }),


  getters:{
    draft:(state) => clone(state.objetivo)
  },

  mutations:{
    clear(state){
      state.objetivo = {
        id:null,
        nombre:'',
        descripcion:null,
        indicador:1,
        tipo_objetivo:1,
        tipo_medicion:1,
        objetivo:0
      }
    },

    setObjetivo(state,objetivo){
      if(objetivo){
        state.objetivo = objetivo
      }
    },

    setObjetivos(state,objetivos){

      if(objetivos.length){
        state.objetivos = objetivos
      }

    }
  },

  actions:{

    fetch({commit},objetivo_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/objetivos/${objetivo_id}/fetch-data`).then(({data}) => {
          commit('setObjetivo',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/objetivos/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/objetivos/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/objetivos`,datos).then(({data}) => {

            if(data.result){
              commit('setObjetivo',data.objetivo)
            }

            resolve(data)

          }).catch(e => reject(e))
        }

      })
    },

    eliminar({commit},objetivo_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/objetivos/${objetivo_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    getObjetivos({commit}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/objetivos/get-all`).then(({data}) => {
          commit('setObjetivos',data)
        })
      })
    },

    tableroRecursosHumanos({commit},datos){
        return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/recursos-humanos`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },

    tableroOperacion({commit},datos){
        return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/operacion`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },

    tableroAgencias({commit},datos){
       return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/agencias`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },

    tableroIngresos({commit},datos){
       return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/ingresos`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },
     tableroEgresos({commit},datos){
       return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/egresos`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },

    tableroAportacionAlDestino({commit},datos){
       return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/aportacion-al-destino`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    },

    tableroCalidad({commit},datos){
       return new Promise((resolve, reject) => {
          axios.post(`/api/tablero/objetivos/calidad`,datos)
          .then(({data}) => resolve(data))
          .catch(e => reject(e))
        })
    }



  }
}