export default{
  namespaced:true,

  state:() => ({
    respuesta:{
      id:null,
      pregunta_id:null,
      encuesta_id:null,
      respuesta:'',
      pregunta:null,
      valor:0,
      encuesta:null
    },

    respuestas:[]
  }),


  getters:{
    draft:(state) => clone(state.respuesta)
  },

  mutations:{

    clear(state){
      state.respuesta = {
        id:null,
        pregunta_id:null,
        encuesta_id:null,
        respuesta:'',
        pregunta:null,
        valor:0,
        encuesta:null
      }
    },

    setRespuesta(state,respuesta){
      if(respuesta){
        state.respuesta = respuesta
      }
    }
  },

  actions:{

    fetch({commit},respuesta_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/respuestas/${respuesta_id}/fetch-data`).then(({data}) => {
          commit('setRespuesta',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/respuestas/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(val => {
        formData.append(val,datos[val])
      })

      const headers = {
        headers:{
          ContentType:'multipart/form-data'
        }
      }

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/respuestas/${datos.id}`,formData,headers).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/respuestas`,formData,headers).then(({data}) => {
            if(data.result){
              commit('setRespuesta',data.respuesta)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },

    guardarPublic({commit},datos){


      return new Promise((resolve, reject) => {
         axios.post(`/api/cuestionarios/public-form-response`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },



    eliminar({commit},respuesta_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/respuestas/${respuesta_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },
  }

}