export default{
   namespaced:true,
   state:() =>({
      restriccion:{
        id:null,
        fecha:null,
        motivo:'',
        mensaje:'',
        tour_id:null,
        tour:null
      },

      restricciones:[]

   }),


   getters:{

      draft(state){
         return clone(state.restriccion)
      }
   },


   mutations:{

      clear(state){
        state.restriccion = {
          id:null,
          fecha:null,
          motivo:'',
          mensaje:'',
          tour_id:null,
          tour:null
         }
      },

      setRestriccion(state,restriccion){

         if(restriccion){
            state.restriccion = restriccion
         }
        
      },

      update(state,data){

         if(data){
            let i = state.restricciones.findIndex(val => val.id === data.id)

            if(i != -1){
               state.restricciones[i] = data
            }

            if(state.restriccion.id == data.id){
                state.restriccion = data
            }

         }

      },

      put(state,restriccion_id){

         state.restricciones.splice(
            state.restricciones.findIndex(val => val.id === restriccion_id),
            1
         )

      },

      push(state,restriccion){
         
         state.restricciones.push(restriccion)

      },

      setRestricciones(state,restricciones){
         state.restricciones = restricciones
      }

   },

   actions:{

    
      fetchDataTour({commit},tour_id){
        return new Promise((resolve, reject) => {
            axios.get(`/api/restricciones/tour/${tour_id}/fetch-data`).then(({data}) => {
               resolve(data)
            }).catch(e => reject(e))
        })
      },

      fetchData({commit},data){

       return  new Promise((resolve, reject) => {

               axios.post('/api/restricciones/fetch-data', data).then(({ data: datos }) => {
                  commit('setRestricciones', datos.restricciones)
                  resolve(datos)
               }).catch(e => reject(e))
            })
      },


      guardar({commit},datos){

         return new Promise((resolve, reject) => {
            
               if(datos.id){

                  axios.put(`/api/restriccions/${datos.id}`,datos).then(({data}) => {

                     if(data.result){
                        commit('update',data.restriccion)
                     }
                     resolve(data)
                  }).catch(e => reject(e))

               }else{

                  axios.post(`/api/restriccions`,datos).then(({data}) => {

                     if (data.result) {
                        commit('push', data.restriccion)
                     }
                     resolve(data)

                  }).catch(e => reject(e))
               }

         })

      },


      eliminar({commit},restriccion_id){

         return new Promise((resolve, reject) => {
            axios.delete(`/api/restriccions/${restriccion_id}`).then(({data}) => {

               if(data.result){
                  commit('put',restriccion_id)
               }
               resolve(data)
            }).catch(e => reject(e))


         })
      },


      fetch({commit},restriccion_id){

         return new Promise((resolve, reject) => {
            
            axios.get(`/api/restricciones/${restriccion_id}/fetch-data`).then(({data}) => {
               commit('setRestriccion',data)
               resolve(data)

            }).catch(e => reject(e))

         })
      }

   }

}
