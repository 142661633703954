export default{
  namespaced:true,

  state:() => ({
    termino:{
      id:null,
      terminos:'',
      rol_id:null,
      model_id:null,
      model_type:null,
      model:null
    },

    terminos:[],
    models:[]
  }),


  getters:{
    draft:(state) => clone(state.termino)
  },

  mutations:{

    clear(state){
      state.termino = {
        id:null,
        terminos:'',
        rol_id:null,
        model_id:null,
        model_type:null,
        model:null
      }
    },

    setTermino(state,termino){
      if(termino){
        state.termino = termino
      }
    },


    setTerminos:(state,terminos) => {
      state.terminos = terminos
    },

    setModels(state,models) {
      state.models = models
    }

  },

  actions:{

    fetch({commit},termino_id){

      return new Promise((resolve, reject) => {

        axios.get(`/api/terminos/${termino_id}/fetch-data`).then(({data}) => {
          commit('setTermino',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {

        axios.post('/api/terminos/fetch-data',datos).then(({data}) => {

          commit('setTerminos',data.terminos);
          resolve(data);

        }).catch(e => {
          reject(e)
        });

      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/terminos/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/terminos`,datos).then(({data}) => {
            if(data.result){
              commit('setTermino',data.termino)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},termino_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/terminos/${termino_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    getListModelos({commit},rol_id){
        return new Promise((resolve, reject) => {
          
          axios.get(`/api/terminos/rols/${rol_id}/list-models`).then(({data}) => {
            commit('setModels',data)
            resolve(data)
          }).catch(e => {
              reject(e)
          })

        })
    },

    agenciaFetchTerminos({commit},agencia_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/agencias/${agencia_id}/fetch-terminos`).then(({data}) => {
          
          commit('setTermino',data)
          resolve(data)

        }).catch(e => reject(e))
      })
    },

    fetchTerminos({commit},{model_id,model_type}){
       return new Promise((resolve, reject) => {
        axios.get(`/api/terminos/${model_type}/${model_id}/fetch-terminos`).then(({data}) => {
          
          commit('setTermino',data)
          resolve(data)

        }).catch(e => reject(e))
      })
    }

  }
}