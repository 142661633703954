export default {
  namespaced:true,
  state:() =>({
    
    cuestionario:{
      id:null,
      titulo:'',
      descripcion:null,
      activo:false,
      duracion:1,
      hook:3,
      prioridad:1,
      usuario_id:null,
      usuario:null,
      rol_id:null,
      rol:null,
      slug:null,
      preguntas:[],
      medicion:1,
      rango_fecha:false,
      fecha_inicio:null,
      fecha_fin:null,
      valor:0,

    },

    cuestionarios:[],

    options_medicions: [
      {text:'Satisfacción del pasajero',value:1},
      {text:'Satisfacción de Recursos Humanos (Traveliers,Admins y Coordinadores)',value:2},
      {text:'Satisfacción del negocio',value:8},
      {text:'Satisfacción del promotor',value:9},
      {text:'Competencia de Recursos Humanos',value:3},
      {text:'Cumplimiento de recursos humanos',value:4},
      {text:'Satisfacción del Transporte',value:5},
      {text:'Uniformidad',value:6},
      {text:'Desempeño del negocio',value:7},


    ]

  }),

  getters:{
    draft:(state) => clone(state.cuestionario)
  },



  mutations:{

    clear(state){
      state.cuestionario = {
         id:null,
        titulo:'',
        descripcion:null,
        activo:false,
        duracion:1,
        hook:3,
        prioridad:1,
        usuario_id:null,
        usuario:null,
        rol_id:null,
        rol:null,
        slug:null,
        preguntas:[],
        medicion:1,
        rango_fecha:false,
        fecha_inicio:null,
        fecha_fin:null,
        valor:0,

      }
    },


    setCuestionarios(state,cuestionarios){
      if(cuestionarios){
        state.cuestionarios = cuestionarios
      }
    },


    setCuestionario(state,cuestionario){
      if(cuestionario){
        state.cuestionario = cuestionario
      }
    }


  },


  actions:{

    fetch({commit},cuestionario_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/cuestionarios/${cuestionario_id}/fetch-data`).then(({data}) => {

          commit('setCuestionario',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/cuestionarios/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/cuestionarios/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/cuestionarios`,datos).then(({data}) => {
            
            if(data.result){
              commit('setCuestionario',data.cuestionario)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},cuestionario_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/cuestionarios/${cuestionario_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })

    },

    cargarCuestionario({commit},{salida_id,reserva_id,hook}){

      return new Promise((resolve,reject) => {

        axios.put(`/api/cuestionarios/salida/${salida_id}/reserva/${reserva_id}`,{hook}).then(({data}) => {
          commit('setCuestionario',data);
          resolve(data)
        }).catch(e => reject(e));

      })
    },

    fetchDataUsers({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/cuestionarios/fetch-data-users`,datos).then(({data} ) => resolve(data)).catch(e => reject(e))
      })
    },

     fetchDataForSlug({commit},{slug,email}){
      return new Promise((resolve,reject) => {
        axios.put(`/api/cuestionarios/slug/${slug}/fetch-data`,{email}).then(({data}) => {
          commit('setCuestionario',data.cuestionario);
          resolve(data)
        }).catch(e => reject(e));
      })
    },

    clonar({commit},cuestionario_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/cuestionarios/${cuestionario_id}/clonar`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    iniciarEncuesta({commit},{salida_id,reserva_id,hook}){
       return new Promise((resolve,reject) => {

        axios.put(`/api/cuestionarios/salida/${salida_id}/reserva/${reserva_id}/iniciar-encuesta`,{hook}).then(({data}) => {
          commit('setCuestionario',data.cuestionario);
          resolve(data)
        }).catch(e => reject(e));

      })
    },


    fetchCuestionariosSalida({commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/cuestionarios/fetch-data-salida`,datos).then(({data}) => resolve(data)).catch(e => {
            reject(e)
        });

      })
    }

  }

}