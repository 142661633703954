
export default{
  namespaced:true,

  state:() => ({
    proveedor:{
      id:null,
      nombre:'',
      logotipo:null,
      activo:false,
      descripcion:'',
      email:null,
      telefono:null,
      direccion:'',
      persona_contacto:null,
      seg_telefono:null,
      lat:null,
      lng:null,
      ciudad_id:null,
      estado_id:null,
    },

    proveedores:[]
  }),


  getters:{
    draft:(state) => clone(state.proveedor)
  },

  mutations:{
    clear(state){
      state.proveedor = {
        id:null,
        nombre:'',
        logotipo:null,
        activo:false,
        descripcion:'',
        email:null,
        telefono:null,
        direccion:'',
        persona_contacto:null,
        seg_telefono:null,
        lat:null,
        lng:null,
        ciudad_id:null,
        estado_id:null,
      }
    },

    setProveedor(state,proveedor){
      if(proveedor){
        state.proveedor = proveedor
      }
    }
  },

  actions:{

    fetch({commit},proveedor_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/proveedors/${proveedor_id}/fetch-data`).then(({data}) => {
          commit('setProveedor',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/proveedors/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(val => {
        formData.append(val,datos[val])
      })

      const headers = {
        headers:{
          ContentType:'multipart/form-data'
        }
      }

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/proveedors/${datos.id}`,formData,headers).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/proveedors`,formData,headers).then(({data}) => {
            if(data.result){
              commit('setProveedor',data.proveedor)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},proveedor_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/proveedors/${proveedor_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    cambiarStatus({commit},{activo,proveedor_id}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/proveedors/${proveedor_id}/cambiar-status`,{activo}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    listSelect({commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/proveedors/list-select`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    }

  }

}