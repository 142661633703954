import store from '@/store';

import {computed} from 'vue';

const promotor_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.id){
    return usuario.id
  }
  return null
});

export default [
  {
      path:'/',
      name:'perfil.promotor.home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
      },
  },

  
    /*****************************************/
   /* Convenio y terminos de Promotor
   /*************************************** */


   {
    path:'/promotor/terminos',
    name:'perfil.promotor.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Promotor').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Término no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },


   {
    path:'/promotor/convenios',
    name:'perfil.promotor.convenios',
    beforeEnter: (to, from, next) => {
      store.dispatch('termino/fetchTerminos',{model_id:promotor_id.value,model_type:'Promotor'}).then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Convenio no asociado')
        }
      })
    },
    component: () => import('views/perfiles/termino.vue'),
    meta:{
      resource:'Perfil convenios',
      action:'read',
    },
   },



   /*****************************************/
   /* Reservas
   /*************************************** */

   {
    path:'/promotor/reservar',
    component: () => import('views/perfiles/index.vue'),
    children:[
      {
        path:'',
        name:'perfil.reserva.create',
        beforeEnter:(from,to,next) => {
          store.commit('reserva/clear')
          store.commit('tour/clear')
          next()
        },
        component: () => import('views/perfiles/promotor/reservar.vue'),
        meta:{
              resource:'Perfil reservaciones',
              action:'write',
              pageTitle:'Generar Reserva'
            },
      },
      {
        path:':id/edit',
        name:'perfil.reserva.edit',
        props:true,
        beforeEnter: (to, from, next) => {
          store.dispatch('reserva/fetch',to.params.id).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        component: () => import('views/perfiles/promotor/reservar.vue'),
        meta:{
              resource:'Perfil reservaciones',
              action:'write',
              pageTitle:'Editar Reserva'
            },
      }
    ]
   },

    {
    path:'/promotor/reservas',
    name:'perfil.reserva.list',
    component: () => import('views/perfiles/promotor/reservas.vue'),
    meta:{
      resource:'Perfil reservaciones',
      action:'write',
      pageTitle:'Reservas'
    }
   },

   /*****************************************/
   /* Tours
   /*************************************** */

   {
    path:'/perfiles/tours',
    component: () => import('views/perfiles/index.vue'),
    children:[
      {
        path:'',
        name:'perfil.tour.list',
        component: () => import('views/perfiles/promotor/tourList.vue'),
        meta:{
              resource:'Perfil tour',
              action:'read',
              pageTitle:'Tours'
            },
      },

      {
        path:'tips',
        name:'perfil.tour.tips',
        component: () => import('views/perfiles/promotor/tourTips.vue'),
        meta:{
          resource:'Perfil tour',
          action:'write',
          pageTitle:''
        },
      },


      {
        path:':id/itinerario',
        name:'perfil.tour.itinerario',
        component: () => import('views/perfiles/promotor/tourItinerario.vue'),
        props:true,
        beforeEnter: (to, from, next) => {
          store.dispatch('tour/fetch',to.params.id).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        meta:{
              resource:'perfil tour',
              action:'write',
              pageTitle:'Itinerario',
              navActiveLink:'perfil.tour.list',
              breadcrumb:[
                  {text:'Tours',to:{name:'perfil.tour.list'},active:false},
                  {text:'Itinerario',active:true},
               ]
        },
      },

       {
        path:':id/acerca',
        name:'perfil.tour.acerca',
        component: () => import('views/perfiles/promotor/tourAcerca.vue'),
        props:true,
        beforeEnter: (to, from, next) => {
          store.dispatch('tour/fetch',to.params.id).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        meta:{
              resource:'Perfil tour',
              action:'write',
              pageTitle:'Acerca del Tour',
              navActiveLink:'perfil.tour.list',
              breadcrumb:[
                  {text:'Tours',to:{name:'perfil.tour.list'},active:false},
                  {text:'Acerca',active:true},
               ]
        },
      },
     
    ]
   },

    /*****************************************/
   /* Pagos
   /*************************************** */
  {
      path:'/promotor/pagos',
      name:'perfil.promotor.pagos',
      component: () => import('views/perfiles/cartera.vue'),
      meta:{
        resource:'pagos',
        action:'read',
        pageTitle:'Pagos',
                
      },
    },

    /*****************************************/
    /* Reservaciones
    /*************************************** */
    {
      path:'/promotor/reportes',
      component:() => import('components/Views.vue'),
      children:[
        {
          path:'reservaciones',
          name:'reporte.promotor.reservaciones',
          props:() => ({
            promotorId:promotor_id.value
          }),
          component:() => import('views/reportes/promotor/reservaciones.vue'),
          meta:{
            resource:'Reporte promotor reservaciones',
            action:'read',
            pageTitle:'Reporte de reservaciones'
          }
        }
      ]
    }
];
