export default{
  namespaced:true,

  state:() => ({
    pago:{
      id:null,
      monto:0,
      comprobante:null,
      status:1,
      is_paypal:false,
      paypal_id:null,
      negocio_id:null,
      negocio:null,
    },

    pagos:[]
  }),


  getters:{
    draft:(state) => clone(state.pago)
  },

  mutations:{
    clear(state){
      state.pago = {
        id:null,
        monto:0,
        comprobante:null,
        status:1,
        is_paypal:false,
        paypal_id:null,
        negocio_id:null,
        negocio:null
      }
    },

    setPago(state,pago){
      if(pago){
        state.pago = pago
      }
    }
  },

  actions:{

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/pago-negocios/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(val => {
        formData.append(val,datos[val])
      })

      const headers = {
        headers:{
          ContentType:'multipart/form-data'
        }
      }

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/pago-negocios/${datos.id}`,formData,headers).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/pago-negocios`,formData,headers).then(({data}) => {
            // if(data.result){
            //   commit('setPago',data.pago)
            // }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },

    eliminar({commit},pago_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/pago-negocios/${pago_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    aprobarPago({commit},pago_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/pago-negocios/${pago_id}/aprobar-pago`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    rechazarPago({commit},pago_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/pago-negocios/${pago_id}/rechazar-pago`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
  }
}