export default {
  namespaced:true,
  state:() =>({
    
    convenio:{
      id:null,
      convenio:'',
      rol_id:null,
      rol:null,
      model_id:null,
      model_type:null,
      model:null

    },

    convenios:[]

  }),

  getters:{
    draft:(state) => clone(state.convenio)
  },



  mutations:{

    clear(state){
      state.convenio = {
        id:null,
        convenio:'',
        rol_id:null,
        rol:null,
        model_id:null,
        model_type:null,
      }
    },


    setConvenios(state,convenios){
      if(convenios){
        state.convenios = convenios
      }
    },


    setConvenio(state,convenio){
      if(convenio){
        state.convenio = convenio
      }
    }


  },


  actions:{

    fetch({commit},convenio_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/convenios/${convenio_id}/fetch-data`).then(({data}) => {

          commit('setConvenio',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchPorPerfil({commit},perfil){

      return new Promise((resolve, reject) => {
        axios.get(`/api/convenios/${perfil}/fetch-data-for-rol`).then(({data}) => {
          commit('setConvenio',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/convenios/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/convenios/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/convenios`,datos).then(({data}) => {
            
            if(data.result){
              commit('setConvenio',data.convenio)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},convenio_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/convenios/${convenio_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    fetchConvenio({commit},convenio_name){

      return new Promise((resolve, reject) => {
        axios.get(`/api/convenios/fetch/${convenio_name}`).then(({data}) => {
          if(data.id){
            commit('setConvenio',data)
          }

          resolve(data)
        }).catch(e => reject(e))
        
      })
    }



  }

}