export default {
  namespaced:true,
  state:() =>({
    
    transportista:{
      id:null,
      nombre:'',
      encargado_id:null,
      choferes:[],
      logotipo:null,

    },

    transportistas:[]

  }),

  getters:{
    draft:(state) => clone(state.transportista)
  },



  mutations:{

    clear(state){
      state.transportista = {
        id:null,
        nombre:'',
        encargado_id:null,
        choferes:[],
        logotipo:null,
      }
    },


    setTransportistas(state,transportistas){
      if(transportistas){
        state.transportistas = transportistas
      }
    },


    setTransportista(state,transportista){
      if( transportista){
        state.transportista = transportista
      }
    }


  },


  actions:{

    fetch({commit},transportista_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/transportistas/${transportista_id}/fetch-data`).then(({data}) => {

          commit('setTransportista',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/transportistas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    uploadLogotipo({commit},datos){

      return new Promise((resolve, reject) => {

        let formData = new FormData();
        formData.append('logotipo', datos.logotipo)
        formData.append('_method','PUT')
        axios.post(`/api/transportistas/${datos.transportista_id}/upload-logo`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
        }).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })

    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/transportistas/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/transportistas`,datos).then(({data}) => {
            
            if(data.result){
              commit('setTransportista',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},transportista_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/transportistas/${transportista_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    agregarChofer({state},chofer_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/transportistas/${state.transportista.id}/agregar-chofer/${chofer_id}`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },

     quitarChofer({state},chofer_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/transportistas/${state.transportista.id}/quitar-chofer/${chofer_id}`).then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },


    getListSelect({commit}){

      return new Promise((resolve, reject) => {
        axios.get(`/api/transportistas/list-select`).then(({data}) => resolve(data)).catch(e => reject(e))

      })

    }

  }

}