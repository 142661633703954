
export default{
  namespaced:true,

  state:() => ({
    entradaSalida:{
      id:null,
      tipo:1, // 1 => entrada , 2 => salida
      monto:0,
      fecha:null,
      estado_cuenta_id:null,
      concepto:'',
      usuario_id:null,
      estadoCuenta:null,
      usuario:null,
      comprobante:null,
      destino:1, // 1 => sistema , 2 => perfil
    },

    entrada_salidas:[]
  }),


  getters:{
    draft:(state) => clone(state.entradaSalida)
  },

  mutations:{
    clear(state){
      state.entradaSalida = {
        id:null,
        tipo:1, // 1 => entrada , 2 => salida
        monto:0,
        fecha:null,
        estado_cuenta_id:null,
        concepto:'',
        usuario_id:null,
        estadoCuenta:null,
        usuario:null,
        comprobante:null,
        destino:1, // 1 => sistema , 2 => perfil

      }
    },

    setEntradaSalida(state,entradaSalida){
      if(entradaSalida){
        state.entradaSalida = entradaSalida
      }
    }
  },

  actions:{

    fetch({commit},entrada_salida_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/entrada-salidas/${entrada_salida_id}/fetch-data`).then(({data}) => {
          commit('setEntradaSalida',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/entrada-salidas/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k]);
      })


      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT');
          
          axios.post(`/api/entrada-salidas/${datos.id}`,formData,{
            headers:{
              ContentType:'multipart/form-data'
            }
          }).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/entrada-salidas`,formData,{
            headers:{
              ContentType:'multipart/form-data'
            }
          }).then(({data}) => {
            if(data.result){
              commit('setEntradaSalida',data.entrada_salida)
            }
            resolve(data)
          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},entrada_salida_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/entrada-salidas/${entrada_salida_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    }

  }

}