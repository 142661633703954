export default{
	namespaced:true,

	state(){
		return{
			mensaje:{
        id:null,
        mensaje:null,
        tipo_mensaje:1,
        usuario_id:null,
        usuario:null,
        chat_id:null,
        chat:null
				
			},

			mensajes:[

			]
		}
	},


	getters:{



	},

	mutations:{
	

		setMensajes(state,data){
			state.mensajes = data;
		},

		pushMensaje(state,mensaje){
			state.mensajes.push(mensaje);
		},


		setMensaje(state,mensaje){
			state.mensaje = mensaje
		},

		clear(state){
			state.mensaje = {
				id:null,
        mensaje:null,
        tipo_mensaje:1,
        usuario_id:null,
        usuario:null,
        chat_id:null,
        chat:null
			}
		},

		

	},

	actions:{

		fetchData({commit},chat_id){
			return new Promise((resolve, reject) => {
        
        axios.get(`/api/mensajes/chat/${chat_id}`).then(({data}) => {
          commit('setMensajes',data)
          resolve(data)
        }).catch(e => reject(e))

			})
		},

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
          let formData = new FormData();

          Object.keys(datos).forEach(k => {
            formData.append(k,datos[k])
          })

          axios.post(`/api/mensajes`,formData,{
            headers:{
              ContentType:'multipart/form-data'
            }
          }).then(({data}) => {
            if(data.result){
              commit('pushMensaje',data.mensaje)
            }
            resolve(data)
          }).catch(e => {
            reject(e)
          })
      })
      
    }


		
	}

}
