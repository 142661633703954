
export default{
  namespaced:true,

  state:() => ({
    cumplimiento:{
      id:null,
      monto:0,
      tipo:1,
      fecha:null,
    },

    cumplimientos:[],
   
  }),


  getters:{
    draft:(state) => clone(state.cumplimiento)
  },

  mutations:{
    clear(state){
      state.cumplimiento = {
        id:null,
        monto:0,
        tipo:1,
        fecha:null,
      }
    },

    setCumplimiento(state,cumplimiento){
      if(cumplimiento){
        state.cumplimiento = cumplimiento
      }
    },

    setCumplimientos(state,cumplimientos){

      if(cumplimientos.length){
        state.cumplimientos = cumplimientos
      }

    }
  },

  actions:{

    fetch({commit},cumplimiento_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/cumplimientos/${cumplimiento_id}/fetch-data`).then(({data}) => {
          commit('setCumplimiento',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/cumplimientos/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/cumplimientos/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/cumplimientos`,datos).then(({data}) => {

            if(data.result){
              commit('setCumplimiento',data.cumplimiento)
            }

            resolve(data)

          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},cumplimiento_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/cumplimientos/${cumplimiento_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },

 

  }
}