export default{

  namespaced:true,

  state:() => ({

    acreditacion:{
      id:null,
      monto:null,
      usuario_id:null,
      movimiento_id:null,
      usuario:null,
      movimiento:null,
      fecha:null
    },

    acreditaciones:[]

  }),

  getters:{
    draft:(state) => clone(state.acreditacion), 
  },



  mutations:{

    clear(state){
      state.acreditacion = {
        id:null,
        monto:null,
        usuario_id:null,
        movimiento_id:null,
        usuario:null,
        movimiento:null,
        fecha:null
      }
    },


    setAcreditaciones(state,acreditaciones){
      
      if(acreditaciones){
        state.acreditaciones = acreditaciones
      }

    },


    setAcreditacion(state,acreditacion){
      if( acreditacion){
        state.acreditacion = acreditacion
      }
    }


  },


  actions:{

    fetch({commit},acreditacion_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/acreditacions/${acreditacion_id}/fetch-data`).then(({data}) => {
          commit('setAcreditacion',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/acreditacions/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {

        if(datos.id){

          axios.put(`/api/acreditacions/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
          
        }else{
          
          axios.post(`/api/acreditacions`,datos).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},acreditacion_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/acreditacions/${acreditacion_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }
  }
}