export default{

  namespaced:true,

  state:() => ({
    pago:{
      id:null,
      monto:null,
      comprobante:null,
      usuario_id:null,
      movimiento_id:null,
      status:1,
    },

    pagos:[]
  }),

  getters:{
    draft:(state) => clone(state.pago), 
    statuses:(state) => {
      let statuses = [
        {text:'Solicitado',value:1},
        {text:'En proceso',value:2},
        {text:'Pagado',value:3},
        {text:'Rechazado',value:4},
      ];

      return statuses;
    }
  },



  mutations:{

    clear(state){
      state.pago = {
        id:null,
        monto:null,
        comprobante:null,
        usuario_id:null,
        movimiento_id:null,
        status:1,
      }
    },


    setPagos(state,pagos){
      if(pagos){
        state.pagos = pagos
      }
    },


    setPago(state,pago){
      if( pago){
        state.pago = pago
      }
    }


  },


  actions:{

    fetch({commit},pago_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/pagos/${pago_id}/fetch-data`).then(({data}) => {

          commit('setPago',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/pagos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {

        let formData = new FormData();

        Object.keys(datos).forEach(option => {
          formData.append(option,datos[option])
        })

        if(datos.id){
          formData.append('_method',"PUT");

          axios.post(`/api/pagos/${datos.id}`,formData,{
            headers:{
              ContentType:'multipart/form-data'
            }
          }).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
          
        }else{
          
          axios.post(`/api/pagos`,formData,{
            headers:{
              ContentType:'multipart/form-data'
            }
          }).then(({data}) => {
            
            if(data.result){
              commit('setPago',data.pago)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},pago_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/pagos/${pago_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }



  }
}