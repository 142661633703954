export default {
  namespaced:true,
  state:() =>({
    
    comentario:{
      id:null,
      comentario:'',
      audio:null,
      usuario_id:null,
      salida_id:null,
      tipo_comentario:1,
      usuario:null
    },

    comentarios:[]

  }),

  getters:{
    draft:(state) => clone(state.comentario)
  },



  mutations:{

    clear(state){
      state.comentario = {
        id:null,
        comentario:'',
        audio:null,
        usuario_id:null,
        salida_id:null,
        tipo_comentario:1,
        usuario:null
      }
    },


    setComentarios(state,comentarios){
      if(comentarios){
        state.comentarios = comentarios
      }
    },


    setComentario(state,comentario){
      if( comentario){
        state.comentario = comentario
      }
    },

    push(state,comentario){
      if(comentario.id){
        state.comentarios.push(comentario) 
      }
    }


  },


  actions:{

    fetchData({commit},salida_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/salidas/${salida_id}/comentarios/fetch-data`).then(({data}) => {

          commit('setComentarios',data)
          resolve(data)
          
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      let formData = new FormData();

      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k]);
      })

      return new Promise((resolve, reject) => {
         axios.post(`/api/comentarios`,formData,{
          headers:{
            ContentType:'multipart/form-data'
          }
         }).then(({data}) => {
            
            if(data.result){
              commit('push',data.comentario)
            }
            resolve(data)
          }).catch( e => reject(e))

      })
    },

    eliminar({state,commit},comentario_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/comentarios/${comentario_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

  }

}