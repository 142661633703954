export default {
  namespaced:true,
  state:() =>({
    
    recomendacion:{
      id:null,
      titulo:'',
      recomendacion:''
    },

    recomendaciones:[]

  }),

  getters:{
    draft:(state) => clone(state.recomendacion)
  },



  mutations:{

    clear(state){
      state.recomendacion = {
         id:null,
      titulo:'',
      recomendacion:''
      }
    },


    setRecomendacions(state,recomendacions){
      if(recomendacions){
        state.recomendaciones = recomendacions
      }
    },


    setRecomendacion(state,recomendacion){
      if( recomendacion){
        state.recomendacion = recomendacion
      }
    }


  },


  actions:{

    fetch({commit},recomendacion_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/recomendacions/${recomendacion_id}/fetch-data`).then(({data}) => {

          commit('setRecomendacion',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/recomendacions/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/recomendacions/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/recomendacions`,datos).then(({data}) => {
            
            if(data.result){
              commit('setRecomendacion',data.recomendacion)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},recomendacion_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/recomendacions/${recomendacion_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getList({state,commit}){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/recomendacions/get-list`).then(({data}) => {
          commit('setRecomendacions',data)
          resolve(data)
        }).catch(e => reject())

      })
    }



  }

}