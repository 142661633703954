export default{
  namespaced:true,

  state:() => ({
    comision:{
      id:null,
      forma_pago:null,
      tipo_comision:1,
      comision:0,
      negocio_id:null,
      is_producto:false,
      producto_vendible:'',
      cada:1,
      negocio:null,
      con_menores:true,
      precio_producto:0,
      is_rango_pasajero:true,
      is_rango_grupo:false,
      rango_pasajeros:[],
      paga_llegada:false
    },
    comisiones:[],

    options_tipo_comision:[
      {
        value:1,
        text:'Llegada, monto fijo por personas'
      },
       {
        value:2,
        text:'Llegada, comisión variable sobre ventas'
      },
      {
        value:5,
        text:'Llegada, monto fijo sobre ventas'
      },

      {
        value:3,
        text:'Comisión, monto fijo por venta'
      },
      {
        value:4,
        text:'Comisión, comisión variable sobre ventas'
      },

      {
        value:6,
        text:'Comisión, monto fijo por llegada'
      },
       {
        value:7,
        text:'Comisión, monto fijo por llegada, rango de pasajeros'
      },
       {
        value:8,
        text:'Comisión, monto fijo por llegada, grupo de personas'
      }
    ]
  }),


  getters:{
    draft:(state) => clone(state.comision)
  },

  mutations:{

    clear:(state) => {
      state.comision = {
        id:null,
        forma_pago:null,
        tipo_comision:1,
        comision:0,
        
        negocio_id:null,
        is_producto:false,
        producto_vendible:null,
        cada:1,
        negocio:null,
        con_menores:true,
        precio_producto:0,
        is_rango_pasajero:true,
        is_rango_grupo:false,
        rango_pasajeros:[],
        paga_llegada:false
      }
    },

    setComision(state,comision){

      if(comision){
        state.comision = comision
      }

    },

    setComisiones(state,comisions){
      if(comisions){
        state.comisiones = comisions
      }
      
    }
  },

  actions:{

    fetch({commit},comision_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/comisions/${comision_id}/fetch-data`).then(({data}) => {
          commit('setComision',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({state,commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/comisions/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject());
      })
    },

    guardar({commit,state},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          
          axios.put(`/api/comisions/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => {
            reject(e)
          })

        }else{
          
          axios.post(`/api/comisions`,datos).then(({data}) => {
            commit('setComision',data.comision)
            resolve(data)
          }).catch(e => reject(e))

        }
      })
    },

    eliminar({commit},comision_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/comisions/${comision_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getListSelect({commit}){
      
      return new Promise((resolve, reject) => {
        axios.get(`/api/comisions/get/list-select`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
      

    },


    cargarComisionNegocio({commit},usuario_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/comisions/negocio/for-user/${usuario_id}`).then(({data}) => {

          commit('setComisiones',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    }

  }
}