export default {
  namespaced:true,
  state:() =>({
    
    cupon:{
      id:null,
      nombre:'',
      monto:1,
      tipo_descuento:1, // 1 => porcentaje , 2 => monto
      observacion:null,
      tour_id:''
    },

    cupones:[]

  }),

  getters:{
    draft:(state) => clone(state.cupon)
  },



  mutations:{

    clear(state){
      state.cupon = {
        id:null,
        nombre:'',
        monto:1,
        tipo_descuento:1, // 1 => porcentaje , 2 => monto
        observacion:null,
        tour_id:''
      }
    },


    setCupones(state,cupons){
      if(cupons){
        state.cupones = cupons
      }
    },


    setCupon(state,cupon){
      if( cupon){
        state.cupon = cupon
      }
    }


  },


  actions:{

    fetch({commit},cupon_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/cupons/${cupon_id}/fetch-data`).then(({data}) => {

          commit('setCupon',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/cupons/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchDataAsignaciones({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/cupons/asignacion/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },



    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/cupons/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/cupons`,datos).then(({data}) => {
            
            if(data.result){
              commit('setCupon',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },


    guardarAsignacion({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/cupons/${datos.cupon_id}/new-asignacion`,datos).then(({data}) => {
          resolve(data)  
        }).catch(e => reject(e))
      })
    },

    eliminarAsignacion({commit},{cupon_id,usuario_id}){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/cupons/${cupon_id}/eliminar-asignacion`,{usuario_id}).then(({data}) => resolve(data)).catch(e  => reject(e))
      })
    },

    eliminar({state,commit},cupon_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/cupons/${cupon_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
      
    },

    getCuponPorNombre({commit},cupon_name){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/cupons/${cupon_name}/get-cupon-por-name`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    }



  }

}