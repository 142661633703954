import store from '@/store';
import {computed} from 'vue';

const coordinador_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.id){
    return usuario.id
  }
  return null
});

export default [
  {
      path:'/',
      name:'perfil.coordinador.home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
         
      },
  },
  
   /*****************************************/
   /* Chat en linea
   /*************************************** */
   {
      path:'/chat-cityfuntastic/:chatId?',
      name:'chat',
      props:true,
      component:() => import('views/chat/Chat.vue'),
      meta:{
         resource:'chat',
         action:'read',
         contentClass:'chat-application',
         contentRenderer:'sidebar-left'
      }
   },

    /*****************************************/
   /* Convenio y terminos de Coordinador
   /*************************************** */


   {
    path:'/coordinador/terminos',
    name:'perfil.coordinador.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Coordinador de operación').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Término no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },


   {
    path:'/coordinador/convenios',
    name:'perfil.coordinador.convenios',
    beforeEnter: (to, from, next) => {
      store.dispatch('termino/fetchTerminos',{model_id:coordinador_id.value,model_type:'Coordinador de operación'}).then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Convenio no asociado')
        }
      })
    },
    component: () => import('views/perfiles/termino.vue'),
    meta:{
      resource:'Perfil convenios',
      action:'read',
    },
   },

   /*****************************************/
   /* Pagos
   /*************************************** */
  {
      path:'/coordinador/pagos',
      name:'perfil.coordinador.pagos',
      component: () => import('views/perfiles/cartera.vue'),
      meta:{
        resource:'pagos',
        action:'read',
        pageTitle:'Pagos',
      },
  },

   /*****************************************/
   /* Cobranza
   /*************************************** */
  {
      path:'/coordinador/cobranza',
      component: () => import('components/Views.vue'),
      children:[
        {
          path:'',
          name:'cobranza',
          component:() => import('views/cobranza/list.vue'),
          meta:{
            pageTitle:'Cobranza a negocios',
            resource:'Cobranza',
            action:'read',
          }

        }
      ]
  },



   /*****************************************/
   /* Rol de trabajo
   /*************************************** */
  {
    path:'/coordinador/rol-trabajo',
    component:() => import('components/Views.vue'),

    children:[
        {
          path:'',
          component:() => import('views/rol-trabajo/coordinador.vue'),
          name:'coordinador.rol',
          meta:{
            resource:'Rol de trabajo',
            action:'read',
            pageTitle:'Rol de trabajo'
          }
        },
        {
          path:':id/itinerario-tour',
          props:true,
          component:()=>import('views/rol-trabajo/itinerarioTour.vue'),
          name:'coordinador.rol.itinerario',
          beforeEnter: (to, from, next) => {

              store.dispatch('salida/fetch',to.params.id).then(({id}) => {
                if(id){
                    next()
                }
              })
              
          },
          meta:{
            navActiveLink:'coordinador.rol',
            resource:'Rol de trabajo',
            action:'read',
            breadcrumb:[
              {text:'Rol de trabajo',to:{name:'coordinador.rol'},active:false},
              {text:'Itinerario de tour',active:true}
            ]
          }
        }
    ]
  },

    /*****************************************/
   /* Reportes
   /*************************************** */
  {
    path:'/reportes',
    component: () => import('components/Views.vue'),
    children:[
      {
        path:'coordinador/ingresos/semanales',
        name:'reporte.coordinador.ingresos.semanales',
        component:() => import('views/reportes/coordinador/ingresoSemanal.vue'),
        meta:{
          pageTitle:'Ingresos Semanal',
          resource:'Reporte ingresos semanales',
          action:'read',
        }
      },
      {
        path:'coordinador/puntos-promocion',
        name:'reporte.coordinador.agencias',
        component: () => import('views/reportes/puntos-promocion/list.vue'),
        meta:{
          pageTitle:'Puntos de promoción (Agencias)',
          resource:'Reporte puntos de promoción',
          action:'read',
        }
      },

      {
        path:'coordinador/puntos-promocion/:agenciaId/promotores',
        name:'reporte.coordinador.agencias.promotores',
        component: () => import('views/reportes/puntos-promocion/promotores.vue'),
        props:true,
        beforeEnter:(to,from,next) => {
          store.dispatch('agencia/fetch',to.params.agenciaId).then(({id}) => {
            if(id){
              next()
            }
          })
        },
        meta:{
          pageTitle:'Promotores',
          resource:'Reporte puntos de promoción',
          action:'read',
          navActiveLink:'reporte.coordinador.agencias',
          breadcrumb:[
            {text:'Puntos de promoción',to:{name:'reporte.coordinador.agencias'},active:false},
            {text:'Promotores ',active:true},

          ]
        }
      }
    ]
  }
];
