import store from '@/store';
import {computed} from 'vue';

const administrador_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.id){
    return usuario.id
  }
  return null
});

export default [


    /*****************************************/
    /* Mi billetera 
    /*************************************** */
    {
      path:'/mi-billetera',
      name:'mi.billetera',
      component:() => import('views/miBilletera.vue'),
      meta:{
        resource:'Mi billetera',
        action:'read',
        pageTitle:'Mi billetera',
      }
    },

    /*****************************************/
    /* Retiros 
    /*************************************** */
    {
        path:'/mis-retiros',
        name:'perfil.retiros',
        component: () => import('views/perfiles/cartera.vue'),
        meta:{
          resource:'Retirar fondos',
          action:'read',
          pageTitle:'Retiros',
        },
    },

   /*****************************************/
   /* Convenio y terminos de travelier
   /*************************************** */
   {
    path:'/administrador/terminos',
    name:'perfil.administrador.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Administrador').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Término no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },

   {
    path:'/administrador/convenios',
    name:'perfil.administrador.convenios',
    beforeEnter: (to, from, next) => {
      store.dispatch('termino/fetchTerminos',{model_id:administrador_id.value,model_type:'Administrador'}).then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Convenio no asociado')
        }
      })
    },
    component: () => import('views/perfiles/termino.vue'),
    meta:{
      resource:'Perfil convenios',
      action:'read',
    },
   },

];