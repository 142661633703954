export default {
  namespaced:true,
  state:() =>({
    
    convenio:{
      id:null,
      convenio:'',
      negocio_id:null,
      negocio:null,
      tipo_cobro:2, // 1 => Manual, 2 => automático
      concepto_cobro:'',
      fecha_cobro:1, // 1 => primeros, 2 => 15 de cada mes , 3 => últimos de cada mes
      ultimo_cobro:null
    },

    convenios:[]

  }),

  getters:{
    draft:(state) => clone(state.convenio)
  },



  mutations:{

    clear(state){
      state.convenio = {
          id:null,
          convenio:'',
          negocio_id:null,
          negocio:null,
          tipo_cobro:2, // 1 => Manual, 2 => automático
          concepto_cobro:'',
          fecha_cobro:1, // 1 => primeros, 2 => 15 de cada mes , 3 => últimos de cada mes
          ultimo_cobro:null
      }
    },


    setConvenios(state,convenios){
      if(convenios){
        state.convenios = convenios
      }
    },


    setConvenio(state,convenio){
      if(convenio){
        state.convenio = convenio
      }
    }


  },


  actions:{

    fetch({commit},convenio_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/convenio-pagos/${convenio_id}/fetch-data`).then(({data}) => {

          commit('setConvenio',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/convenio-pagos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/convenio-pagos/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/convenio-pagos`,datos).then(({data}) => {
            
            if(data.result){
              commit('setConvenio',data.convenio)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},convenio_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/convenio-pagos/${convenio_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    aplicarCobro({state,commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/convenio-pagos/${datos.convenio_id}/aplicar-cobro`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => {
          reject(e)
        })

      })
    }
  }
}