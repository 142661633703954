import store from '@/store';
import {computed} from 'vue';

const travelier_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.id){
    return usuario.id
  }
  return null
});

export default [
  {
      path:'/',
      name:'perfil.travelier.home',
      component: () => import('views/home.vue'),
      meta:{
         resource:'home',
         action:'read',
      },
  },


    /*****************************************/
   /*  Rol de travelier
   /*************************************** */
  {
    path:'/travelier/rol-trabajo',
    component:() => import('views/perfiles/travelier/rolTrabajo.vue'),
    name:'perfil.travelier.rol',
    meta:{
        resource:'Rol de trabajo',
        action:'read',
        pageTitle:'Rol de Trabajo'
    }
  },

   /*****************************************/
   /*  Salida Hoy
   /*************************************** */

   {
    path:'/travelier/salida-hoy/:salida',
    props:true,
    beforeEnter: (to, from, next) => {
      store.dispatch('salida/fetch',to.params.salida).then(({id,status}) => {
        if(id && [2].includes(status)){
          next()
        }
      })
    },
    component:() => import('views/perfiles/salidaHoy.vue'),
    name:'salida.hoy',
    meta:{
      resource:'Salidas hoy',
      action:'read',
    }

   },

   /*****************************************/
   /*  Salidas history
   /****************************************/
  {
    path:'/travelier/salidas',
    component:() => import('views/perfiles/index.vue'),
    children:[
      {
        path:'',
        component:() => import('views/perfiles/travelier/salidas/list.vue'),
        name:'travelier.salidas',
        meta:{
          pageTitle:"Tours Realizados",
          resource:'Travelier salidas',
          action:'read',
        }
      },
      {
        path:':id/info',
        name:'salida-info',
        props:true,
        component:() => import('views/perfiles/salidaHoy.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('salida/fetch',to.params.id).then(({id,status}) => {
            if(id && [1,2,3].includes(status)){
              next()
            }
          })
        },
        meta:{
          resource:'Salidas hoy',
          action:'read',
        }
        
      }
    ]
  },

   /*****************************************/
   /* Convenio y terminos de travelier
   /*************************************** */


   {
    path:'/travelier/terminos',
    name:'perfil.travelier.terminos',
    beforeEnter: (to, from, next) => {
      store.dispatch('convenio/fetchPorPerfil','Travelier').then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Término no registrado')
        }
      })
    },
    component: () => import('views/perfiles/convenio.vue'),
    meta:{
      resource:'Perfil términos',
      action:'read',
    },
   },


   {
    path:'/travelier/convenios',
    name:'perfil.travelier.convenios',
    beforeEnter: (to, from, next) => {
      store.dispatch('termino/fetchTerminos',{model_id:travelier_id.value,model_type:'Travelier'}).then(({id}) => {
        if(id){
          next()
        }else{
          toast.error('Convenio no asociado')
        }
      })
    },
    component: () => import('views/perfiles/termino.vue'),
    meta:{
      resource:'Perfil convenios',
      action:'read',
    },
   },

   /*****************************************/
   /* Ingresos
   /*************************************** */

   {
    path:'/travelier/ingresos',
    component:() => import('views/perfiles/index.vue'),
    children:[
      {
        path:'',
        component:() => import('views/perfiles/travelier/ingresos/list.vue'),
        name:'perfil.travelier.ingresos',
        meta:{
          resource:'Travelier ingresos',
          action:'read',
          pageTitle:'Ingresos'
        }
      },


      {
        path:'semanal',
        props:() => ({
            'travelierId' : travelier_id.value
        }),
        component:() => import('views/perfiles/travelier/ingresos/semanal.vue'),
        name:'perfil.travelier.ingresos-semanal',
        meta:{
          resource:'Travelier ingresos',
          action:'read',
          pageTitle:'Ingresos Semanal'
        }
      },


      {
        path:'por-tour',
        component:() => import('views/perfiles/travelier/ingresos/diarios.vue'),
        name:'perfil.travelier.ingresos.diarios',
        meta:{
          resource:'Travelier ingresos',
          action:'read',
          pageTitle:'Ingresos',
          navActiveLink:'perfil.travelier.ingresos',
          breadcrumb:[
            {text:'Ingresos',to:{name:'perfil.travelier.ingresos'},active:false},
            {text:'Diarios',active:true},

          ]
        }
      },

      {
        path:'reporte',
        component:() => import('views/perfiles/travelier/reportes/ingresos.vue'),
        name:'reporte.travelier.ingresos',
        meta:{
          resource:'Travelier ingresos',
          action:'read',
          pageTitle:'Reporte de ingresos',
          // navActiveLink:'perfil.travelier.ingresos',
          // breadcrumb:[
          //   {text:'Ingresos',to:{name:'perfil.travelier.ingresos'},active:false},
          //   {text:'Diarios',active:true},
          // ]
        }
      }

    ]
   },
  
   /*****************************************/
   /* Chat en linea
   /*************************************** */
   {
      path:'/chat-cityfuntastic/:chatId?',
      name:'chat',
      props:true,
      component:() => import('views/chat/Chat.vue'),
      meta:{
         resource:'chat',
         action:'read',
         contentClass:'chat-application',
         contentRenderer:'sidebar-left'
      }
   },


   /*****************************************/
   /* Pagos
   /*************************************** */
  {
      path:'/travelier/pagos',
      name:'perfil.travelier.pagos',
      component: () => import('views/perfiles/cartera.vue'),
      meta:{
        resource:'pagos',
        action:'read',
        pageTitle:'Pagos',
      },
    },


    
];
