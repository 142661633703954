<template>
  <div id="app" class="h-100" :class="[skinClasses]" ref="refApp">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <reload-prompt />


    <datos-aliados v-model="showDatosAliados" :actions="actionDatosAliados" :datos="datoAliado"
      :aliadoType="aliadoType" />
    <datos-reserva v-model="showReserva" :actionsReserva="actionReserva" />
    <academia v-if="is_loggin && usuario.rol && usuario.rol.academia && (usuario.rol.academia.length > 0)" />
  </div>
</template>

<script>

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch, onMounted, toRefs, onActivated, computed, provide, ref, onUnmounted } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar, useNetwork } from '@vueuse/core'
import store from '@/store'
import { useRoute } from 'vue2-helpers/vue-router';
import useAuth from '@core/utils/useAuth.js';
import ReloadPrompt from 'components/ReloadPrompt.vue';

import { useSwipe } from '@vueuse/core';
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout.js';

export default {

  components: {
    LayoutHorizontal: () => import('@/layouts/horizontal/LayoutHorizontal.vue'),
    LayoutVertical: () => import('@/layouts/vertical/LayoutVertical.vue'),
    LayoutFull: () => import('@/layouts/full/LayoutFull.vue'),
    ScrollToTop,
    ReloadPrompt,
    DatosAliados: () => import('components/DatosAliados.vue'),
    DatosReserva:() => import('components/DatosReserva.vue'),
    academia: () => import('components/Academia.vue'),

  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')



  },


  setup(props) {

    const route = useRoute();
    const contentLayoutType = computed(() => store.state.appConfig.layout.type)
    const channelUsers = Echo.channel('usuarios');
    const {salidas} = toRefs(store.state.salida)
    const {usuario} = toRefs(store.state.usuario);
    const showReserva = ref(false);
    const showAcademia = ref(false);


    provide('showAcademia', showAcademia)

    const aliadoType = ref(1);
    const datoAliado = ref({
      nombre: '',
      encargado_id: null,
      empleados: [],
      precios: {
        adulto: 0,
        menor: 0,
        infante: 0
      },
      logotipo: null,
      telefono: null,
      email: null,
      sitio_web: null,
      direccion: '',
      lat: 20.6565177,
      lng: -105.211358,
      ciudad_id: null,
      estado_id: null,
      activo: false,
      descripcion: '',
      persona_contacto: null,
      seg_telefono: null,
      breve: '',
      codigo_postal: null,
      usuario_id: null,
      ciudad: null,
      estado: null,
      usuario: null,
      facturar: false,
      datos_facturacion: null,
      horario_trabajo: null,
      dias_laborables: [],
      visitas: [],
      choferes: [],
    });

    const showDatosAliados = ref(false);

    provide('showDatosAliados',showDatosAliados);
    provide('datoAliado',datoAliado)
    provide('aliadoType', aliadoType)
    provide('showReserva',showReserva)
    provide('channelUsers', channelUsers)


    const actionDatosAliados = () => {

      const setTypeAliado = (type) => aliadoType.value = type;

      const setDatoAliado = (datos) => {
        datoAliado.value = datos
      }

      const clearDatos= () => {
        datoAliado.value = {
          nombre: '',
          encargado_id: null,
          empleados: [],
          precios: {
            adulto: 0,
            menor: 0,
            infante: 0
          },
          logotipo: null,
          telefono: null,
          email: null,
          sitio_web: null,
          direccion: '',
          lat: 20.6565177,
          lng: -105.211358,
          ciudad_id: null,
          estado_id: null,
          activo: false,
          descripcion: '',
          persona_contacto: null,
          seg_telefono: null,
          breve: '',
          codigo_postal: null,
          usuario_id: null,
          ciudad: null,
          estado: null,
          usuario: null,
          facturar: false,
          datos_facturacion: null,
          horario_trabajo: null,
          dias_laborables: [],
          visitas: [],
          choferes: [],
        }
      }

      const abrir = () => showDatosAliados.value = true
      const cerrar = () => {
        showDatosAliados.value = false
      }

      const aperturarDatosAliados = (datos,type) => {
        let tipos = ['agencia','negocio','proveedor','transportista'];

        store.dispatch(`${tipos[type-1]}/fetch`,datos.id).then((d) => {
          setDatoAliado(d)
          setTypeAliado(type)
          abrir();
        })

        
      }

      return {
        clearDatos,
        cerrar,
        setDatoAliado,
        setTypeAliado,
        aperturarDatosAliados
      }

    }

    provide('actionDatosAliados', actionDatosAliados);

    const {
      is_loggin
    } = useAuth();

    const layout = computed(() => {

      if (route.meta.layout === 'full') return 'layout-full'

      return `layout-${contentLayoutType.value}`

    })


    const actionReserva = () => {

      const abrirReserva = (reserva_id) => {

        store.dispatch('reserva/fetch',reserva_id).then(({id}) => {
          if(id){
            showReserva.value = true
          }
        })

      }

      const cerrar = (next) => {
        showReserva.value = false
        store.commit('reserva/clear')
        next()
      }

      return {
        cerrar,
        abrirReserva
      }

    }

    provide('actionReserva');

    const cargarSistema = () => {
      store.dispatch('sistema/fetchPublic')
    }

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const { isOnline } = useNetwork();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()

    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    const cargarSalidasHoy = () => {
      store.dispatch('salida/salidasHoy', usuario.value.id)
    }

    provide('cargarSalidasHoy',cargarSalidasHoy)

    onMounted(() => {

      if (localStorage.getItem('token')) {
        store.commit('usuario/cargarUser', JSON.parse(localStorage.getItem('userData')));
        cargarSalidasHoy()
      }


      if(usuario.value.id){
        channelUsers
        .listen('.SalidaCreated', ({ model }) => {
          cargarSalidasHoy()
        })
        .listen('.SalidaSaved', ({ model }) => {
          cargarSalidasHoy()
        })
        .listen('.SalidaUpdated', ({ model }) => {
          cargarSalidasHoy()
        })    

      }
    })

    onUnmounted(() => {
      channelUsers.stopListening('.SalidaCreated')
      .stopListening('.SalidaSaved')
      .StopListening('.SalidadUpdated');

    })

    watch(isOnline, (val) => {

      if(!val) {
        toast.info("La conexíón ha caido, informalo a tú operadora")
      }

    })

    cargarSistema()

    const refApp = ref(null)
    // Swipe

    const { toggleVerticalMenuActive } = useVerticalLayout();

    const { isSwiping, direction, coordsStart,coordsEnd} = useSwipe(refApp,{
      passive:true,
      disableTextSelect:true
    });
    const anchoSidebar = computed(() => store.state.windowWidth)

    provide('isSwiping',isSwiping)
    provide('directionSwiping', direction)
    provide('coordsStart',coordsStart)
    provide('coordsEnd', coordsEnd)

    // watch(isSwiping, (v) => {
    //   console.log(anchoSidebar.value)
    //   if(v && anchoSidebar.value <= 1200){
    //     console.log('swiper',v)
    //     // toggleVerticalMenuActive();
    //   }
    // })

    return {
      skinClasses,
      enableScrollToTop,
      layout,
      contentLayoutType,
      is_loggin,
      actionDatosAliados,
      showDatosAliados,
      datoAliado,
      aliadoType,
      showReserva,
      actionReserva,
      refApp,
      showAcademia,
      usuario

    }
  },


}
</script>
