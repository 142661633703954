export default {
  namespaced:true,
  state:() =>({
    
    reserva:{
      id:null,
      pasajero_id:null,
      tour_id:null,
      usuario_id:null,
      fecha:null,
      nombre:'',
      email:null,
      telefono:null,
      adultos:1,
      menores:0,
      infantes:0,
      promo_code:null,
      total_promo:0,
      deposito:0,
      balance:0,
      sub_total:0,
      total_reserva:0,
      observacion:'',
      estatus:1, // 1 => activa, 2 => Show  , 3 => no SHow , 4 => cancelada, 5 => no asignadas
      pasajero:null,
      tour:null,
      usuario:null,
      pick_up_id:null,
      hora_pickup:null,
      agencia_id:null,
      habitacion:null,
      idioma:1, //1 => es , 2 => en
      unidad:{
        asientos:null,
        precio:0,
        precio_agencia:0
      }
    },

    reservas:[],
    statuses:[
      {text:'Activas',value:1,color:'success'},
      {text:'Show',value:2,color:'warning'},
      {text:'NO Show',value:3,color:'primary'},
      {text:'Canceladas',value:4,color:'danger'},
      {text:'No Asignadas',value:5,color:'danger'},
    ]
  }),

  getters:{
    draft:(state) => clone(state.reserva),

    getStatus:(state) => (status) => {

      let statuses = ['Activa','Show','No Show','Cancelada','No Asignada']
      return statuses[status - 1]

    },

    getVariantStatus:(state) => status => {
      let statusesVariant = ['primary','success','danger','danger','danger']
      return statusesVariant[status - 1]
    }
  },



  mutations:{

    clear(state){
      state.reserva = {
        id:null,
        pasajero_id:null,
        tour_id:null,
        usuario_id:null,
        fecha:null,
        nombre:'',
        email:null,
        telefono:null,
        adultos:1,
        menores:0,
        infantes:0,
        promo_code:null,
        total_promo:0,
        deposito:0,
        balance:0,
        sub_total:0,
        total_reserva:0,
        observacion:'',
        estatus:1, // 1 => activa, 2 => Show , 3 => No show, 4 => cancelada , 5 => No asignada
        pasajero:null,
        tour:null,
        usuario:null,
        pick_up_id:null,
        hora_pickup:null,
        agencia_id:null,
        habitacion:null,
        idioma:1,
        unidad:{
          asientos:0,
          precio:0,
          precio_agencia:0
        }
      }
    },


    setReservas(state,reservas){
      if(reservas){
        state.reservaes = reservas
      }
    },


    setReserva(state,reserva){
      if( reserva){
        state.reserva = reserva
      }
    }


  },


  actions:{

    
    fetch({commit},reserva_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/reservas/${reserva_id}/fetch-data`).then(({data}) => {

          commit('setReserva',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchPublic({commit},reserva_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/reservas/${reserva_id}/get/fetch-data-public`).then(({data}) => {
          commit('setReserva',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/reservas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchDataPromotor({commit},datos){
       return new Promise((resolve, reject) => {
        axios.post(`/api/reservas/promotor/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/reservas/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/reservas`,datos).then(({data}) => {
            
            if(data.result){
              commit('setReserva',data.pick_up)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},reserva_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/reservas/${reserva_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    getReservasPorFecha({commit},{fecha,tour_id}){
      return new Promise((resolve, reject) => {
        
        axios.post(`/api/reservas/get/por-fechas`,{fecha,tour_id}).then(({data}) => resolve(data)).catch(e => reject(e))

      })
    },
    cancelar({commit},reserva_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/reservas/${reserva_id}/cancelar-reserva`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    editTravelier({commit},datos){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/reservas/${datos.reserva_id}/edit-travelier`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    checkOK({commit},reserva_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/reservas/${reserva_id}/check-in-ok`).then(({data}) => resolve(data)).catch(e => reject(e))

      });

    },

    checkNoShow({commit},reserva_id){
       return new Promise((resolve, reject) => {
          axios.get(`/api/reservas/${reserva_id}/check-no-show`).then(({data}) => resolve(data)).catch(e => reject(e))
       });
    },

    fetchDataPasajero({commit},pasajero_id){
      return new Promise((resolve,reject) => {
          axios.get(`/api/reservas/pasajero/${pasajero_id}/fetch-data`).then(({data}) => {
            resolve(data)  
          }).catch(e => {
            reject(e)
          })

      })
    },

    fetchDataPasajeroHistorial({commit},pasajero_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/reservas/pasajero/${pasajero_id}/historial-reserva/fetch-data`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },


      cobrarBalancePendiente({commit},datos){
      return new Promise((resolve, reject) => {

        axios.put(`/api/reservas/${datos.reserva_id}/cobrar-balance-pendiente`,datos)
        .then(({data}) => resolve(data))
        .catch(e => reject(e));
        
      })
     },

     aceptarTerminos({commit},res_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/reservas/${res_id}/aceptar-terminos`).then(({data}) => resolve(data)).catch(e => reject(e))

      })
     },


     totalReservas({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/tablero/total-reservas`,{filtro}).then(({data}) => resolve(data)).catch(e => reject(e))
      })
     },

     totalPasajeros({commi},usuario_id){
      return new Promise((resolve, reject) => {
        axios.post(`/api/tablero/total-pasajeros`,{usuario_id}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
        
      })
     },

     fetchDataTablero({commit},datos){
      
      return new Promise((resolve, reject) => {
        axios.post(`/api/reservas/fetch-data-tablero`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })

    },

    guardarHoraPickUp({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/reservas/${datos.id}/udpate-hora-pickup`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    reporteReservasPromotor({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/promotor/reportes/fetch-data-reservaciones`,datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }


  }

}