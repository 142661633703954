export default {
  namespaced:true,
  state:() =>({
    
    pregunta:{
      id:null,
      pregunta:'',
      tipo_respuesta:6,
      option_respuesta:[
        {option:"",valor:0}
      ],
      cuestionario_id:null,
      cuestionario:null,
      valor:0,
    },

    preguntas:[]

  }),

  getters:{
    draft:(state) => clone(state.pregunta),

    tipoPreguntasOptions(state){
      let options =  [
        {label:'Respuesta corta',value:1,icon:'fa-grip-lines',disabled:false},
        {label:'Varias opciones',value:2,icon:'fa-square-check',disabled:false},
        {label:'Desplegable',value:3,icon:'fa-rectangle-list',disabled:false,},
        {label:'Fecha',value:4,icon:'fa-calendar-days',disabled:true},
        {label:'Hora',value:5,icon:'fa-clock',disabled:true},
        {label:'Casillas',value:6,icon:'fa-list-ul',disabled:false},
      ];

      return options;
    }
  },



  mutations:{

    clear(state){
      state.pregunta = {
        id:null,
        pregunta:'',
        tipo_respuesta:6,
        option_respuesta:[
          {option:"",valor:0}
        ],
        cuestionario_id:null,
        cuestionario:null,
        valor:0,

      }
    },


    setPreguntas(state,preguntas){
      if(preguntas){
        state.preguntas = preguntas
      }
    },


    setPregunta(state,pregunta){
      if(pregunta){
        state.pregunta = pregunta
      }
    },

    agregarOption(state){

      state.pregunta.option_respuesta.push({
        option:"",
        valor:0
      })
    },

    quitarOpciones(state){
      state.pregunta.option_respuesta = [
        {option:"",valor:0}
      ]
    }


  },


  actions:{

    fetch({commit},pregunta_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/preguntas/${pregunta_id}/fetch-data`).then(({data}) => {

          commit('setPregunta',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/preguntas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          
          axios.put(`/api/preguntas/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))

        }else{
          
          axios.post(`/api/preguntas`,datos).then(({data}) => {
            
            if(data.result){
              // commit('setpregunta',data.pregunta)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},pregunta_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/preguntas/${pregunta_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    cambiarOrden({commit},{pregunta_id,oldIndex,newIndex}) {
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/preguntas/${pregunta_id}/cambiar-orden`,{oldIndex,newIndex}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    }



  }

}