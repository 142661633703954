
export default{
  namespaced:true,

  state:() => ({
    encuesta:{
      id:null,
      usuario_id:null,
      cuestionario_id:null,
      puntos:0,
      resultado:0,
      salida_id:null,
      negocio_id:null,
      respondida:false,
      usuario:null,
      salida:null,
      negocio:null,
      cuestionario:null,
      respuestas:[]
    },

    encuestas:[]
  }),


  getters:{
    draft:(state) => clone(state.encuesta)
  },

  mutations:{
    clear(state){
      state.encuesta = {
        id:null,
        usuario_id:null,
        cuestionario_id:null,
        puntos:0,
        resultado:0,
        salida_id:null,
        negocio_id:null,
        respondida:false,
        usuario:null,
        salida:null,
        negocio:null,
        cuestionario:null,
        respuestas:[]
      }
    },

    setEncuesta(state,encuesta){
      if(encuesta){
        state.encuesta = encuesta
      }
    }
  },

  actions:{

    fetch({commit},encuesta_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/encuestas/${encuesta_id}/fetch-data`).then(({data}) => {
          commit('setEncuesta',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    fetchData({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post('/api/encuestas/fetch-data',datos).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){

          axios.put(`/api/encuestas/${datos.id}`,datos).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))

        }else{
           axios.post(`/api/encuestas`,datos).then(({data}) => {

            if(data.result){
              commit('setEncuesta',data.encuesta)
            }

            resolve(data)

          }).catch(e => reject(e))
        }

      })
    },


    eliminar({commit},encuesta_id){
      return new Promise((resolve, reject) => {
          axios.delete(`/api/encuestas/${encuesta_id}`).then(({data}) => {
            resolve(data)
          }).catch(e => reject(e))
      })
    },


    responderCuestionario({commit},{enc_id,usuario_id}) {

      return new Promise((resolve, reject) => {
         axios.put(`/api/cuestionarios/${enc_id}/responder-encuesta`,{usuario_id}).then(({data}) => {
          
          resolve(data)
         
        }).catch(e => {
          
          reject(e)

         })
      })
    },

    responderCuestionarioConSalida({commit},{enc_id,salida_id,usuario_id}) {

      return new Promise((resolve, reject) => {
         axios.put(`/api/cuestionarios/${enc_id}/responder-encuesta`,{usuario_id,salida_id}).then(({data}) => {
          
          resolve(data)
         
        }).catch(e => {
          
          reject(e)

         })
      })
      
    },

    responderCuestionarioConSalidaYNegocio({commit},{enc_id,salida_id,negocio_id,usuario_id}) {

      return new Promise((resolve, reject) => {
         axios.put(`/api/cuestionarios/${enc_id}/responder-encuesta`,{usuario_id,salida_id,negocio_id}).then(({data}) => {
          
          resolve(data)
         
        }).catch(e => {
          
          reject(e)

         })
      })
    },


    encuestaCompletada({commit},enc_id){
      return new Promise((resolve, reject) => {
        
        axios.get(`/api/encuestas/${enc_id}/completada`).then(({data}) => {
          resolve(data)
        }).catch(e => reject())

      })
    }

    

  }

}