export default {
  namespaced:true,
  state:() =>({
    
    lugar:{
      id:null,
      nombre:'',
      descripcion:'',
      lng:null,
      lat:null,
      negocio_id:null,
      negocio:null,
      ciudad_id:null,
      ciudad:null,
      estado_id:null,
      estado:null,
      direccion:''
    },

    lugares:[]

  }),

  getters:{
    draft:(state) => clone(state.lugar)
  },



  mutations:{

    clear(state){
      state.lugar = {
        id:null,
        nombre:'',
        descripcion:'',
        lng:null,
        lat:null,
        negocio_id:null,
        negocio:null,
        ciudad_id:null,
        ciudad:null,
        estado_id:null,
        estado:null,
        direccion:''
      }
    },


    setLugares(state,lugares){
      if(lugares){
        state.lugares = lugares
      }
    },


    setLugar(state,lugar){
      if( lugar){
        state.lugar = lugar
      }
    }


  },


  actions:{

    fetch({commit},lugar_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/lugar-turisticos/${lugar_id}/fetch-data`).then(({data}) => {

          commit('setLugar',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/lugar-turisticos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/lugar-turisticos/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/lugar-turisticos`,datos).then(({data}) => {
            
            if(data.result){
              commit('setLugar',data.lugar)
            }
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},lugar_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/lugar-turisticos/${lugar_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    fetchListSelect({commit}){

      return new Promise((resolve,reject) => {
        
        axios.get(`/api/lugares-turisticos/list-select`).then(({data}) => {
          commit('setLugares',data)
          resolve(data)
        }).catch(e => reject(e))


      })

    }



  }

}