export default{
  namespaced:true,


  state(){
    return {
      invitacion:{
        id:null,
        email:'',
        mensaje:'',
        nombre:null,
        rol_id:null,
        rol:null
      },

      invitaciones:[]
    }
  },

  getters:{

    draft(state){
      return state.invitacion
    }

  },

  mutations:{

    clear(state){
      state.invitacion = {
         id:null,
        email:'',
        mensaje:'',
        nombre:null,
        rol_id:null,
        rol:null
      }
    },

    setInvitaciones:(state,data) => {
      state.invitaciones = data
    },

    setInvitacion:(state,invitacion) => {
      state.invitacion = invitacion
     } 



  },
  

  actions:{
    fetchData({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/invitacions/fetch-data`,filtro).then(({data}) => {
          commit('setInvitaciones',data.invitaciones)
          resolve(data)
        }).catch( e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/invitacions`,datos).then(({data}) => {

          if(data.result){
            commit('setInvitacion',data.invitacion)
            
          }
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    recordarInvitacion({commit},invitacion_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/invitacions/${invitacion_id}/recordar`).then(({data}) => {
          resolve(data)
        }).catch( e => reject(e))
        

      })
    },

    fetch({commit},invitacion_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/invitacions/${invitacion_id}/fetch-data`).then(({data}) => {
          if(data){
            commit('setInvitacion',data)
          }
          resolve(data)
        }).catch(e => reject(e)) 

      })
    },

     fetchPublic({commit},invitacion_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/invitacions/${invitacion_id}/public/fetch-data`).then(({data}) => {
          if(data){
            commit('setInvitacion',data)
          }
          resolve(data)
        }).catch(e => reject(e)) 

      })
    },


    eliminar({commit},inv_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/invitacions/${inv_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  

  }

}